.admis-sect{
    .container{
        max-width: 100%;
        padding: 0;
    }
}
.admis-wr{
    display: flex;
    flex-wrap: wrap;
    .admis-img{
        width: 60%;
        @include max(850px) {
            width: 100%;
            order: 2
        }
        .img{
            @include imgPd(636, 984);
        }
        img{
            @include imgCover
        }
    }
.admis-img-content{
        position: relative;
        z-index: 2;
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        .bg{
            position: absolute;
            z-index: -1;
            inset: 0;
            width: 100%;
            height: 100%;
            &::after{
                content: '';
                position: absolute;
                inset: 0;
                width: 100%;
                height: 100%;
                background: linear-gradient(0deg, rgba(0, 34, 68, 0.95) 0%, rgba(0, 34, 68, 0.95) 100%);
            }
            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }
    .admis-content{
        width: calc(8/12*100%);
        @include max(1200px) {
            width: 100%;
            padding: 2rem;
        }
        @include max(850px) {
            width: 80%;
            text-align: center;
        }
        @include max(575px) {
            width: 100%;
        }
        .stt, .tt-sect{
            color: $white-pri;
        }
        .stt{
            font-size: 1.6rem;
            font-weight: 600;
            text-transform: uppercase;
            @include max(1200px) {
                font-size: 1.4rem;
            }
        }
        .tt-sect{
            margin-bottom: 1.2rem;
        }
        .desc{
            font-size: 1.6rem;
            color: #EDEFF2;
            margin-bottom: 2.4rem;
        }
        .btn{
            @include max(850px) {
                margin: 0 auto;
            }
        }
    }
}