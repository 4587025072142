.blog-dt-sect{
    padding-top: 3rem;
    .blog-dt-wr{
        padding-top: 4.8rem;
        @include max(1200px) {
            padding-top: 2.8rem;
        }
        @include max(768px) {
            row-gap: 5rem;
        }
    }
}

.blog-aside{
    transition: all .3s;
    @include min(767px) {
        position: sticky;
        top: var(--size-hd);
        height: 100%;
    }
    // @include max(767px) {
    //     order: -1;
    // }
    .blog-aside-title{
        font-family: var(--font-sec);
        font-size: 2.4rem;
        font-weight: 600;
        color: $color-third;
        margin-bottom: 2.4rem;
        @include max(1200px) {
            font-size: 2rem;
        }
    }
    &.open{
        transform: translate(0,0);
    }
}

.blog-dt-main{
    .img-full, .wp-block-image {
        width: 100%;
        height: 100%;
        aspect-ratio: 800/450;
        img{
            width: 100%;
            height: 100%;
        }
        .txt, .wp-element-caption{
            color: #3A4040;
            font-size: 1.4rem;
            display: block;
            padding-left: 1rem;
            border-left: 1px solid $color-pri;
            margin-top: 1.6rem;
            @include max(575px) {
                font-size: 1.2rem;
                margin-top: 1rem;
            }
        }
    }
    .mona-content{
        color: #3A4040;
        h1{
            @include fz-32;
        }
        a{
            color: #1D86EF;
            font-weight: 600;
            text-decoration: underline;
        }
    }
    .share-blog {
        padding-top: 4.8rem;
        @include max(1200px) {
            padding-top: 2.8rem;
        }
        .label-share{
            display: block;
            font-size: 1.6rem;
            margin-bottom: 1.6rem;
        }
        .share-social{
            .social-link{

            }
        }
    }
}


.blog-govern-dt{
    .blog-dt-main{
        @include max(650px) {
            width: 100%;
        }
        .tt-sect{
            font-weight: 700;
        }
        .mona-content{
            font-family: var(--font-third);
            width: calc(7/9*100%);
            margin: 0 auto;
            box-shadow: 0px 4px 64px 0px rgba(9, 14, 25, 0.08);
            @include max(1024px) {
                width: 100%;
            }        
            p{
                font-size: 2rem;
                @include max(1200px) {
                    font-size: 1.6rem;
                }
            }
        }
        .mona-content-inner{
            font-family: var(--font-third);
            padding: 7.2rem 11.2rem;
            @include max(1200px) {
                padding: 4.2rem 7.2rem;
            }
            @include max(575px) {
                padding: 2rem;
            }
        }
    }
    .blog-aside{
        @include max(650px) {
            width: 35rem;
            position: fixed;
            z-index: 9;
            left: 0;
            top: var(--size-hd);
            bottom: 0;
            background-color: $white;
            padding-top: 1.6rem;
            transform: translate(-100%, 0);
        }
        @include max(575px) {
            width: 85%;
        }
        &.open{
            transform: translate(0,0);
        }
        .btn-aside{
            display: flex;
            justify-content: center;
            align-items: center;
            width: 4rem;
            height: 4rem;
            border-radius: 0 10rem 10rem 0;
            position: absolute;
            top: 50%;
            right: 0;
            transform: translate(100%, -50%);
            background-color: $color-second;
            @include min(650px) {
                display: none;
            }
            .ic{
                display: flex;
                justify-content: center;
                align-items: center;
                i{
                    font-size: 2rem;
                    color: $white;
                }
            }
        }
    }
    .blog-menu{
        font-family: var(--font-sec);
        padding: 0 3.2rem;
        @include max(1200px) {
            padding: 0 1.6rem;
        }
        .tt-sect{
            margin-bottom: 1.6rem;
        }
        .menu-link{
            // font-size: 2rem;
            display: flex;
            font-weight: 600;
            padding: 2rem 0;
            border-top: 1px solid #ECEEF4;
            transition: all .3s;
            @include max(1200px) {
                font-size: 1.6rem;
            }
            &.active{
                color: $color-pri;
            }
        }
    }
}