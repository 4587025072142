.certi-search-sect{
    position: relative;
    z-index: 2;
    margin-bottom: -3rem;
    .certi-search-inner{
        padding: 6rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        row-gap: 2rem;
        @include max(1200px) {
            padding: 3rem;
        }
        @include max(575px) {
            padding: 2rem;
        }
    }
    .certi-search-wr{
        width: calc(11/12*100%);
        margin: 0 auto;
        background-color: $white;
        box-shadow: 0px 16px 56px 0px rgba(0, 0, 0, 0.10);
        @include max(767px) {
            width: 100%;
        }
    }
    .certi-search-content{
        width: calc(5.2/11*100%);
        @include flexCol;
        row-gap: 3rem;
        @include max(1200px) {
            row-gap: 1.4rem;
            width: calc(6.2/12*100%);
        }
        @include max(750px) {
            width: 100%;
            text-align: center;
        }
        .desc{
            @include max(1200px) {
                font-size: 1.6rem;
            }
        }
        .btn{
            @include max(750px) {
                margin: 0 auto;
            }
        }
    }
    .certi-search-img {
        position: relative;
        width: calc(4.8/11*100%);
        display: flex;
        align-items: center;
        @include max(750px) {
            width: 70%;
            margin: 0 auto;
        }
        @include max(575px) {
            width: 100%;
        }
        .certi-search-img-slider{
            background-image: url(/template/assets/images/line-slider.png);
            background-repeat: no-repeat;
            background-size: 100% 100%;
            background-size: 70% 100%;
            padding: 3rem;
            background-position-x: right;
            flex: 1;
            @include max(1200px) {
                padding: 8%;
            }
            .swiper{
                // margin: 0 -1.6rem;
            }
            .swiper-slide{
                width: 100%;
                // padding: 0 1.6rem;
                .img{
                    @include imgPd(330, 330);
                    img{
                        @include imgCover;
                    }
                }
            }
        }
    }
}