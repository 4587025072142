.blog-home{
    .blog-grid-heading{
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }
}

.topic-stt {
    display: block;
    color: $color-pri;
    font-size: 1.6rem;
    font-weight: 700;
    line-height: 150%;
    text-transform: uppercase;
    @include max(1200px) {
        font-size: 1.4rem;
    }
    @include max(575px) {
        font-size: 1.2rem;
    }
}

.home-ab{
    position: relative;
    overflow: hidden;
    .bird-block{
        display: none;
    }
    &::after{
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 150%;
        min-height: 32.4rem;
        background: linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, #FFF 78%);
        transform: rotate(180deg);
        animation: marquee 12s linear alternate infinite;
        @include max(767px) {
            min-height: 11rem;
        }
    }
    .bg{
        position: absolute;
        z-index: -1;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        pointer-events: none;
        background-image: url(/template/assets/images/bg-cloud.jpg);
        animation: marquee 12s linear alternate infinite;
        background-size: 120%;
        background-repeat: no-repeat;
        @include max(1200px) {
            background-size: cover;
        }
        @include max(575px) {
            animation: marquee 18s linear alternate infinite;
        }
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .bird{
        background-image: url(/template/assets/images/bird-flying.svg);
        filter: brightness(0) saturate(100%) invert(3%) sepia(2%) saturate(2907%) hue-rotate(169deg) brightness(88%) contrast(92%);        background-size: auto 100%;
        width: 88px;
        height: 125px;
        will-change: background-position;
        animation-name: fly-cycle;
        animation-timing-function: steps(10);
        animation-iteration-count: infinite;
    }
    .bird-block-item{
        position: absolute;
        left: -3%;
        &:first-child{
            top: 10%;
            @include max(575px) {
                top: 0;
            }
        }
        &:nth-child(2) {
            top: 30%;
            @include max(575px) {
                display: none;
            }
        }
        &:nth-child(3) {
            top: 60%;
            @include max(575px) {
                display: none;
            }
        }
        &:last-child{
            top: 90%;
            @include max(991px) {
                display: none;
            }
        }
    }
    .bird-one{
        animation-duration: 1s;
        animation-delay: -0.5s;
    
    }
    .bird-two{
        animation-duration: 0.9;
        animation-delay: -0.75s;
    
    }
    .bird-three{
        animation-duration:1.25s;
        animation-delay: -0.25s
    
    }
    .bird-four{
        animation-duration: 1.1s;
        animation-delay: -0.5s;
    }
    
    .bird-container {
        // position: absolute;
        // top: 10%;
        // left: -3%;
        transform: scale(0) translateX(-10vw);
        will-change: transform;
        animation-name: fly-right-one;
        animation-timing-function: linear;
        animation-iteration-count: infinite;
    }
        
    .bird-container-one{
        animation-duration: 15s;
        animation-delay: 0;
    }
        
    .bird-container-two{
        animation-duration: 16s;
        animation-delay: 1s;
    }
        
    .bird-container-three{
        animation-duration: 14.6s;
        animation-delay: 9.5s;
    }
        
    .bird-container-four {
        animation-duration: 16s;
        animation-delay: 10.25s;
    }
    .plane{
        position: absolute;   
        width: 100%;
        top: 50%;
        left: 50%;
        // transform: translate(-100%, -50%);
        transform: translate(-50%, -50%);
        transition: all 1.4s ease-in-out;
        @include max(992px) {
            transform: translate(-50%, 0%);
        }
        @include max(992px) {
            top: 18rem;
        }
        &.active{
            // @include min(575px) {
            //     transform: translate(-50%, -50%);
            // }
            // @include min-max(575px,992px) {
            //     transform: translate(-50%, 0);
            // }
            .inner{
                @include min(575px) {
                    animation: plane 5s linear infinite;
                }
            }
        }
        img{
            width: 100%;
            height: 100%;
        }
    }
    .home-ab-main{
        padding: 17rem 0 5rem;
        @include min(1999px) {
            padding: 17rem 0 5rem;
        }
        @include max(1200px) {
            padding: 12rem 0 5rem;
        }
        @include max(575px) {
            padding: 8rem 0 5rem;
        }
        .home-ab-main-wr{
            position: relative;
            display: block;
            padding-top: calc(888/1216*100%);
            // &.active{
            //     @include min(575px) {
            //         .decor-img{
            //             .img{
            //                 &:first-child{
            //                     .inner{
            //                         animation: bounce-in-right 2s forwards;
            //                         animation-delay: 1.4s;
            //                     }
            //                 }
            //                 &:last-child{
            //                     .inner{
            //                         opacity: 1;
            //                         visibility: visible;
            //                         transform: scale(1) translateY(0);
            //                     }
            //                 }
            //             }
            //         }
            //     }
            // }
        }
    }
    .decor-content{
        @include max(991px) {
            @include row(0.8);
            row-gap: 1.6rem;
        }
        @include max(575px) {
            padding-top: 12rem;
        }
        .content{
            width: calc(5.2/12*100%);
            position: absolute;
            z-index: 3;
            backdrop-filter: blur(26.600000381469727px);
            @include max(992px) {
                position: unset;
                width: 50%;
                padding: 0 var(--it-pd);
            }
            @include max(480px) {
                width: 100%;
            }
            &:first-child{
                top: 2rem;
                left: 0;
                // animation-delay: 2.2s;
                @include max(1024px) {
                    top: 0;
                }
                .ic{
                    animation: shakeJump 2.4s linear infinite;
                }
            }
            &:nth-child(2) {
                right: -6.2rem;
                top: 6rem;
                // animation-delay: 2.4s;
                @include min-max(1999px, 2340px) {
                    right: 0;
                }
                @include max(1480px) {
                    right: 0;
                }
                @include max(1024px) {
                    top: 2rem;
                }
                .ic{
                    animation: plane-3 2.4s linear infinite;
                }
            }
            &:nth-child(3) {
                right: -6.2rem;
                bottom: 2rem;
                // animation-delay: 2.6s;
                @include min(1999px) {
                    bottom: 15rem;
                }
                @include min-max(1999px, 2340px) {
                    right: 0;
                }
                @include max(1480px) {
                    right: 0;
                }
                @include max(1024px) {
                    bottom: 0;
                }
                .ic{
                    animation: spring 2.6s linear infinite;
                }
            }
            &:last-child{
                left: 0;
                bottom: 16rem;
                // animation-delay: 2.8s;
                @include min(2000px) {
                    bottom: 33rem;
                }
                @include max(1024px) {
                    bottom: 5rem;
                }
                .ic{
                    animation:  plane-3 2.6s linear infinite;
                }
            }
            .inner{
                padding: 2.4rem;
                animation-delay: 3s;
                background: rgba(255, 255, 255, 0.50);
                box-shadow: 0px 4px 10px 0px rgba(8, 13, 20, 0.05);
                @include max(992px) {
                    height: 100%;
                    padding: 1.4rem;
                }
            }
        }
        .content-wr{
            display: flex;
            @include max(575px) {
                flex-direction: column;
                align-items: center;
                row-gap: 1rem
            }
        }
        .ic{
            @include icon(6.4, 6.4);
            margin-right: 2.4rem;
            @include max(1500px) {
                width: 5.6rem;
                height: 5.6rem;
            }
            @include max(1200px) {
                margin-right: 1.2rem;
                width: 4rem;
                height: 4rem;
            }
            img{
                width: 100%;
                height: 100%;
            }
        }
        .desc{
            @include max(1500px) {
                font-size: 1.6rem;
            }
            @include max(575px) {
                font-size: 1.4rem;
            }
        }
    }
    .decor-img {
        position: absolute;
        z-index: 1;
        top: 0;
        width: calc(5/12*100%);
        left: 50%;
        transform: translate(-50%,0);
        border-radius: 40rem;
        overflow: hidden;
        @include max(575px ){
            width: calc(7/12*100%);
        }
        .decor-img-wr{
            display: block;
            position: relative;
            padding-top: calc(888/592*100%);
        }
        .img{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            .inner{
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                img{
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                }
            }
            &:first-child{
                z-index: 2;
                // .inner{
                //     @include min(575px) {
                //         transform: translate3d(-3000px,0,0) scaleX(3);
                //     }
                // }
            }
            &:last-child{
                .inner{
                    @include min(575px) {
                        // opacity: 0;
                        // visibility: hidden;
                        // transform: scale(0) translateY(-3rem);
                        // transition: all 1s ease-in-out;
                        // transition-delay: 1s;
                        border-radius: 40rem;
                        overflow: hidden;
                    }
                }
            }
        }
    }
}

.home-vd {
    overflow: hidden;
}

.rvw-slider{
    .swiper{
        margin: 0 calc(-1* var(--it-pd));
    }
    .swiper-wrapper{
        transition-timing-function: linear !important;
    }
    .swiper-slide{
        width: calc(3.5/12*100%);
        padding: 0 var(--it-pd);
        @include max(1200px) {
            width: calc(4/12*100%);
        }
        @include max(991px) {
            width: calc(5.5/12*100%);
        }
        @include max(700px) {
            width: 50%;
        }
        @include max(500px) {
            width: 80%;
        }
    }
}

.home-interna{
    position: relative;
    z-index: 2;
    overflow: hidden;
    .bg{
        position: absolute;
        z-index: -1;
        inset: 0;
        width: 100%;
        height: 100%;
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .home-interna-content{
        position: relative;
        z-index: 2;
    }
    .home-interna-content-wr{
        padding-bottom: 10rem;
        @include max(1200px) {
            padding-bottom: 5rem;
        }
    }
}

.home-ach{
    overflow: hidden;
    .heading-home-sect{
        width: calc(6/12*100%);
        margin: 0 auto 4.8rem ;
        @include max(575px) {
            margin: 0 auto 2.8rem;
        }
        @include max(700px) {
            width: 100%;
        }
        .tt-sect{
            margin-bottom: 1.6rem;
        }
        .desc{
            @include max(1620px) {
                font-size: 1.6rem;
            }
            @include max(1200px) {
                font-size: 1.4rem;
            }
        }
    }
    .home-ach-slider{
        padding: 0 4.8rem;
        @include max(1200px) {
            padding: 0 1.5rem;
        }
        .swiper{
            margin: 0 calc(-1*var(--it-pd));
            padding: 12rem 0 22rem;
            @include max(575px) {
                padding: 0;
                margin: 0;
            }
        }
        .swiper-slide{
            position: relative;
            z-index: 3;
            width: 20%;
            padding: 0 var(--it-pd);
            transition: all .4s linear;
            @include max(1200px) {
                width: calc(3/12*100%);
            }
            @include max(850px) {
                width: calc(4/12*100%);
            }
            @include max(575px) {
                width: calc(10/12*100%);
            }
            &:not(.swiper-slide-active){
                position: relative;
                z-index: 1;
                .thumb{
                    &::after{
                        content: '';
                        position: absolute;
                        z-index: 1;
                        inset: 0;
                        width: 100%;
                        height: 100%;
                        background: linear-gradient(0deg, rgba(255, 255, 255, 0.50) 0%, rgba(255, 255, 255, 0.50) 100%);
                    }
                    .thumb-inner{
                        padding-top: calc(280/280*100%);
                    }
                }
            }
            &.swiper-slide-active{
                .thumb-inner{
                    transform: scale(1.7);
                    box-shadow: 0 1rem 2rem 0.5rem rgba(0,0,0,0.1);
                    @include max(575px) {
                        transform: scale(1);
                    }
                }
                .box-ach-content{
                    opacity: 1;
                    visibility: visible;
                }
            }
        }
        .thumb{
            position: relative;
            z-index: 2;
            width: 100%;
            height: 100%;
        }
        .thumb-inner{
            @include imgPd(592, 592);
            transition: all .4s linear;
            img{
                @include imgCover;
            }
        }
        .box-navi{
            position: absolute;
            z-index: 2;
            left: 50%;
            top: 40%;
            transform: translate(-50%, -50%);
            width: calc(4/12*100%);
            @include max(1200px) {
                width: calc(6/12*100%);
            }
            @include max(850px) {
                width: calc(8/12*100%);
            }
            @include max(575px) {
                // top: 35%;
                top: calc((100% - 16.3rem)/2);
                width: calc(10/12*100%);
            }
            .btn-navi{
                width: 4rem;
                height: 4rem;
                border-radius: 50%;
                background-color: $color-pri;
                display: flex;
                justify-content: center;
                align-items: center;
                @include max(1200px) {
                    width: 3.2rem;
                    height: 3.2rem;
                }
                &.swiper-button-disabled{
                    opacity: 1;
                    pointer-events: auto;
                }
                .ic{
                    display: block;
                    width: 2.4rem;
                    height: 2.4rem;
                    @include max(1200px) {
                        width: 2rem;
                        height: 2rem;
                    }
                    img{
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }
    }
    .box-ach-content{
        position: absolute;
        bottom: -12rem;
        left: 50%;
        transform: translate(-50%, 50%);
        font-family: var(--font-sec);
        width: calc(100% + 6rem);
        margin: 0 auto;
        background-color: $color-pri;
        box-shadow: 0px 4px 64px 0px rgba(9, 14, 25, 0.08);    
        // display: none;
        pointer-events: none;
        opacity: 0;
        visibility: hidden;
        transition: all .4s ease-in-out;
        @include max(1620px) {
            bottom: -8rem;
        }
        @include max(850px) {
            width: calc(100% + 8rem);
        }
        @include max(575px) {
            position: unset;
            width: 100%;
            transform: translate(0,0);
            margin-top: 2rem;
        }
        // @include max(575px) {
        //     bottom: 0;
        // }

        .box-ach-content-inner{
            padding: 2.4rem 8rem;
            text-align: center;
            @include max(1620px) {
                padding: 2.4rem 4rem;
            }
            @include max(1200px) {
                padding: 2.4rem  3rem;
            }
            @include min-max(1999px, 2240px) {
                padding: 2.4rem 4rem;
            }
        }
        .num-ach{
            font-size: 6.4rem;
            color: $color-second;
            @include max(1620px) {
                font-size: 5.2rem;
            }
            @include max(1200px) {
                font-size: 3.8rem;
            }
        }
        .txt{
            color: #F5F6F7;
            font-weight: 500;
            @include max(1620px) {
                font-size: 1.6rem;
            }
            @include max(1200px) {
                font-size: 1.4rem;
            }
        }
    }
}

.home-partner{
    overflow: hidden;
    @include max(575px) {
        padding-bottom: 0;
    }
    .home-partner-wr{
        position: relative;
    }
    .partner-slider{
        position: relative;
    }
    .bg{
        position: absolute;
        z-index: -1;
        inset: 0;
        width: 100%;
        &::after{
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            min-height: 36rem;
            background: linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, #FFF 43.48%);
            transform: rotate(180deg);
            @include max(991px) {
                min-height: 20rem;
            }
        }
        &::before{
            content: '';
            position: absolute;
            bottom: -0.2rem;
            left: 0;
            width: 150%;
            min-height: 36rem;
            background: linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, #FFF 43.48%);
            @include max(991px) {
                min-height: 20rem;
            }
        }
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .vapour{
        position: relative;
        bottom: 10rem;
        z-index: 1;
        display: flex;
        margin: 0 2rem;
        @include max(991px) {
            bottom: 0;
        }
        .cir{
            display: block;
            flex: 1;
            height: 10rem;
            background-color: #fff;
            border-radius: 50%;
            opacity: 0;
            filter: blur(10px);
            animation: vapour 5s linear infinite;
            animation-delay: calc(var(--i) * 0.5s);
            @include max(575px) {
                height: 7rem;
            }
        }
    }
    .img-wr{
        position: relative;
        z-index: 2;
        margin-top: -17rem;
        @include max(1200px) {
            margin-top: -10rem;
        }
        @include max(767px) {
            margin-top: 2rem;
        }
        .img{
            &:first-child{
                &.active{
                    transform: scale(1) translate(0,0);
                }
            }
        }
        .img{
            width: 100%;
            &:first-child{
                // transform: scale(0);
                transition: all 3s ease-in-out;
                transform: scale(0.3) translate(-100%, 110%);
                // transition-delay: .4s;
                .img-inner{
                    animation: plane-2 5s linear infinite;
                    @include min(2000px) {
                        text-align: center;
                    }
                }
            }
            &:last-child{
                width: auto;
                position: absolute;
                z-index: -1;
                bottom: 0;
                right: 0;
                .img-inner{
                }
                img{
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
}

.home-blog{
    overflow: hidden;
}

.home-admis{
    overflow: hidden;
}

.home-certi-search{
    overflow: hidden;
    margin-bottom: -3rem;
    .certi-search-sect{
        margin-bottom: 0;
    }
}