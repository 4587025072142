
// color
.cl-pri{
    color: $color-pri;
}

.cl-sec{
    color: $color-second;
}

.cl-third{
    color: $color-third;
}

.cl-white{
    color: $white;
}

.cl-black{
    color: $black;
}

// background
.bg-pri{
    background-color: $color-pri;
}

.bg-sec{
    background-color: $color-second;
}

.bg-third{
    background-color: $color-third;
}

.bg-white{
    background-color: $white;
}

.bg-black{
    background-color: $black;
}