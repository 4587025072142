.daterangepicker {

    &.show-calendar {
        .drp-buttons {
            display: flex;
            justify-content: space-between;
            .btn{
                transition: all .4s ease-in-out;
                &:hover{
                    background-color: $color-pri;
                    color: $white;
                }
            }
        }
    }

    td{
        transition: all .4s ease-in-out;
        &.active{
            background-color: $color-pri;
            border-color: transparent;
            color: #fff;
            &:hover{
                background-color: $color-pri;
                border-color: transparent;
                color: #fff;
            }
        }
        &.available{
            &:hover{
                background-color: $color-pri;
                border-color: transparent;
                color: #fff;
            }
        }
    }

    select {
        &.yearselect, &.monthselect{
            cursor: pointer;
        }
    }
}