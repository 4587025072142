.teacher-team-sect{
    overflow: hidden;
    .bg{
        &::after{
            background: linear-gradient(0deg, rgba(0, 34, 68, 0.95) 0%, rgba(0, 34, 68, 0.95) 100%);
        }
    }
}

.majors-related-sect{
    overflow: hidden;
}

.majors-card-slider-wr{
    .container{
        max-width: calc(100vw - (100vw - 120rem)/2);
        margin: 0 0 0 auto;
        padding: 0;
        @include max(1200px) {
            max-width: 100%;
            padding: 0 1.5rem;
        }
    }
    .swiper{
        margin: 0 calc(-1 * var(--it-pd));
    }
    .swiper-slide{
        width: calc(3.5/12*100%);
        padding: 0 var(--it-pd);
        @include max(1024px) {
            width: calc(4/12*100%);
        }
        @include max(767px) {
            width: 50%;
        }
        @include max(390px) {
            width: 70%;
        }
    }
    .card{
        height: 100%;
        .thumb-card{
            padding-top: calc(300/384*100%);
        }
        .tt-card{
            font-size: 2.4rem;
            font-weight: 600;
            @include max(1200px) {
                font-size: 2rem;
            }
            @include max(575px) {
                font-size: 1.6rem;
            }
        }
    }
}

.tg-content-box{
    @include flexCol;
    row-gap: 6rem;
    @include max(1200px) {
        row-gap: 3rem;
    }
    .box-content{
        overflow: hidden;
    }
    &:first-child{
        position: relative;
        z-index: 2;
        margin-top: -5rem;
    }
    &:nth-child(2){
        .box-content{
            order: -1;
            .box-content-wr{
                flex-direction: row-reverse;
            }
        }
    }
    &:last-child{
        .box-content{
            order: -1;
        }
        .table-info-body{
            .table-info-row{
                &:last-child{
                    .table-info-item{
                        width: 100%;
                    }
                }
            }
        }
    }
}

.curri-info-sect{
    .curri-info-heading{
        width: calc(6/12*100%);
        margin: 0 auto 4.8rem;
        @include max(1200px) {
            width: 100%;
            margin: 0 auto 2.8rem;
        }
        .tt-sect{
            margin-bottom: 1.2rem;
        }
    }
    .table-curri-info{
        margin-bottom: 2.4rem;
    }
}


.value-content-sect{
    overflow: hidden;
}

.value-content-wr{
    .tt-sect{
        @include max(575px) {
            text-align: center;
            text-wrap: balance;
        }
    }
    .value-content-content-wr{
        .content-card-box{
            margin-bottom: 1.6rem;
            &:last-child{
                margin-bottom: 0;
            }
        }
    }
    .value-content-thumb{
        flex-direction: column;
        display: flex;
        justify-content: center;
    }
    .value-content-thumb-wr{
        display: block;
        padding-top: calc(572/592*100%);
        overflow: hidden;
        position: relative;
        .img{
            position: absolute;
            width: calc(3/6*100%);
            img{
                width: 100%;
                height: 100%;
            }
            &:first-child{
                top: 0;
                left: 0;
                width: calc(2.8/6*100%);
                aspect-ratio: 280/180;
            }
            &:nth-child(2){
                bottom: 0;
                left: 0;
                width: calc(2.8/6*100%);
                aspect-ratio: 280/360;
            }
            &:last-child{
                top: 50%;
                transform: translate(0,-50%);
                right: 0;
                aspect-ratio: 280/360;
            }
        }
    }
}