.blog-grid-sect{
    overflow: hidden;
}
.blog-grid-heading{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 4.8rem;
    @include max(1200px) {
        margin-bottom: 2.8rem;
    }
    @include max(575px) {
        justify-content: center;
        text-align: center;
        text-wrap: balance;
        flex-direction: column;
        row-gap: 1rem;
    }
}

.home-blog{
    .blog-grid-heading-lf {
        display: flex;
        flex-direction: column;
    }
}

.blog-grid-wr{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: auto;
    gap: calc(var(--it-pd)*2);
    @include max(850px) {
        @include row(0.8);
        gap: 0;
        row-gap: calc(var(--it-pd)*2);
    }
    .card{
        &:first-child{
            grid-column: 1/3;
            grid-row: 1/3;
            @include max(850px) {
                width: 100%;
                padding: 0 0.8rem
            }
            .thumb-card{
                @include imgPd(382, 592);
                margin-bottom: 2.4rem;
                &:hover{
                    img{
                        transform: translate(-50%, -50%) scale(1.1);
                    }
                }
            }
            .info{
                margin-bottom: 0.4rem;
                .info-item{
                    font-size: 1.4rem;
                }
            }
            .tt-card{
                font-size: 2.4rem;
                margin-bottom: 1.2rem;
                @include max(1200px) {
                    font-size: 2rem;
                }
            }
            .desc-card{
                color: #606069;
                font-size: 1.6rem;
            }
        }
        &:not(:first-child){
            .desc-card{
                display: none;
            }
        }
        @include max(850px) {
            width: 50%;
            padding: 0 0.8rem;
        }
        @include max(390px) {
            width: 100%;
        }
        .info{
            margin-bottom: 0.4rem;
            .info-item{
                font-size: 1.2rem;
            }
        }
        // .link{
        //     margin-top: 2.5rem;
        //     @include max(1200px) {
        //         margin-top: 1.5rem;
        //     }
        // }
    }
}