// Library
@import url("../assets/library/fontawesome/css/all.css");
@import url("../assets/library/fontawesome/fontawesome/fontawesome.css");
@import url("../assets/library/aos/aos.css");
@import url("../assets/library/swiper/swiper-bundle.min.css");
@import url("../assets/library/select2/select2.min.css");
@import url("../assets/library/gallery/lightgallery.min.css");
@import url("../assets/library/fancybox/fancybox.css");
@import url("../assets/library/datetime/daterangepicker.css");
// @import url("../assets/library/magnify/magnify.css");
@import url("../assets/library/splitting/splitting.css");
// @import url("../assets/library/animate/animate.min.css");

// Core
@import "./core/reset";
@import "./core/mona";
@import "./core/variable";
@import "./core/font";
@import "./core/base";
@import "./core/mixin";

// Component
@import "./components/header";
@import "./components/footer";
@import "./components/components";
@import "./components/banner";
@import "./components/contact-fixed";
@import "./components/blog-grid";
@import "./components/card";
@import "./components/admis";
@import "./components/certi-search";
@import "./components/career-card";
@import "./components/partner";
@import "./components/majors-card.scss";
@import "./components/content-card";

// Layout
@import "./layouts/btn";
@import "./layouts/flex";
@import "./layouts/color";
@import "./layouts/keyframe";
@import "./layouts/hambuger";
@import "./layouts/amount";
@import "./layouts/breadcrumbs";
@import "./layouts/scrollBar";
@import "./layouts/social";
@import "./layouts/stars";
@import "./layouts/text";
@import "./layouts/page-numbers";
@import "./layouts/range-slider";
@import "./layouts/swiper";
@import "./layouts/select2";
@import "./layouts/animations";
@import "./layouts/popup";
@import "./layouts/datepicker";

// Pages
@import "./pages/home";
@import "./pages/blog";
@import "./pages/blog-dt";
@import "./pages/career";
@import "./pages/career-dt";
@import "./pages/certi-result";
@import "./pages/contact";
@import "./pages/tranning";
@import "./pages/training-uni";
@import "./pages/training-cntt";
@import "./pages/sinhvien";
@import "./pages/phonghanhchinh";
@import "./pages/about";
// @import "./pages/home";
// @import "./pages/home";
// @import "./pages/home";
// @import "./pages/home";
// @import "./pages/home";
// @import "./pages/home";
// @import "./pages/home";
// @import "./pages/home";
// @import "./pages/home";
// @import "./pages/home";
// @import "./pages/home";
// @import "./pages/home";
// @import "./pages/home";
// @import "./pages/home";
// @import "./pages/home";
// @import "./pages/home";