@import url("../assets/font/Montserrat/stylesheet.css");
@import url("../assets/font/Raleway/stylesheet.css");
@import url("../assets/font/Barlow/stylesheet.css");

* {
    box-sizing: border-box;
}

html {
    font-size: 10px;
    scroll-behavior: smooth;

    @media screen and (min-width: 2000px) {
        font-size: 100%;
    }
}

body {
    font-family: var(--font-pri);
    font-size: 18px;
    line-height: 1.55;
    font-weight: 400;
    color: $color-text;
    overflow: hidden auto;

    @media screen and (min-width: 2000px) {
        font-size: 20px;
    }
}

a {
    text-decoration: none;
    color: inherit;
}

button,
select {
    border: none;
    outline: none;
    cursor: pointer;
    -webkit-appearance: none;
    cursor: pointer;

}

button {
    padding: 0;
    background-color: transparent;
    cursor: pointer;
}

input,
textarea {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: none;
    outline: none;
    font-family: var(--font-sec);
    font-size: 1.6rem;
    color: $black;
    &::placeholder{
        font-size: 1.6rem;
    }
}


.main {
    min-height: 100vh;
}

.container {
    width: 100%;
    max-width: 123rem;
    height: 100%;
    margin: 0 auto;
    padding: 0 1.5rem;
}

.no-scroll {
    touch-action: none;
    -webkit-overflow-scrolling: none;
    overflow: hidden;
    overscroll-behavior: none;
}

.tabPanel{
    display: none;
}

.collapseContent{
    display: none;
}

iframe {
    vertical-align: middle;
}

img {
    max-width: 100%;
    height: auto;
}

.overlay{
    position: fixed;
    inset: 0;
    width: 100%;
    height: 100%;
    z-index: 4;
    background: rgba(0,0,0,0.5);
    display: none;
}

.overlay.active{
    display: block;
}

.overlay-full{
    position: fixed;
    inset: 0;
    width: 100%;
    height: 100%;
    z-index: 50;
    background: rgba(0,0,0,0.5);
    display: none;
}

.overlay-full.active{
    display: block;
}

.d-flex{
    display: flex;
}

.d-flex-col{
    display: flex;
    flex-direction: column;
}

.p-center{
    margin: 0 auto;
}

.p-lf{
    margin-left: auto;
}

.p-rt{
    margin-right: auto;
}

.ss-pd-t{
    padding-top: var(--ss-pd);
}

.ss-pd-b{
    padding-bottom: var(--ss-pd);
}

.ss-pd{
    padding: var(--ss-pd) 0;
}

aside{
    @media (min-width:800px) {
        position: sticky;
        top: 10rem;
        height: 100%;
        overflow: hidden;
    }
    @media (max-width:800px) {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        width: 30rem;
        height: 100%;
        transform: translate(100%, 0);
        overflow: visible;
        transition: all .4s ease-out;
    }
}

.gItem{
    cursor: pointer;
}


.lg-thumb-outer.lg-grab {
    display: none;
}

.lg-download, .lg-autoplay-button, .lg-fullscreen, #lg-zoom-in, #lg-zoom-out
, #lg-actual-size, #lg-share, .lg-flip-ver, .lg-flip-hor, .lg-rotate-left, .lg-rotate-right{
    display: none;
}

// ios
.menu{
    -webkit-overflow-scrolling: touch; 
}

.font-second{
    font-family: var(--font-sec);
}


.info-wr{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    .info-item{
        font-size: 1.4rem;
        line-height: 150%;
        @media (max-width:1200px) {
            font-size: 1.2rem;
        }
        &:first-child{
            margin-right: 0.8rem;
        }
        &:last-child{
            padding-left: 0.8rem;
            border-left: 0.1rem solid #636069;
        }
    }
    .author{
        font-weight: 600;
        color: $color-third;
        font-size: 1.4rem;
        line-height: 150%;
        @media (max-width:575px) {
            font-size: 1.2rem;
        }
    }
    .date{
        font-family: var(--font-sec);
        color: #82838C;
        font-size: 1.4rem;
        line-height: 150%;
        @media (max-width:575px) {
            font-size: 1.2rem;
        }
    }
    .link{
        @media (max-width:1200px) {
            margin-top: 1.5rem;
        }
    }
}


.box-search{
    .result-search{
        font-family: var(--font-sec);
        display: block;
        margin-top: 0.6rem;
        color: #3A4040;
        font-size: 1.2rem;
    }
    .box-search-wr{
        display: flex;
        align-items: center;
        width: 100%;
        height: 4.9rem;
        background-color: #F5F7F7;
        padding-right: 0.8rem;
        overflow: hidden;
        .input{
            width: 100%;
            height: 100%;
        }
        input{
            width: 100%;
            height: 100%;
            padding: 0.8rem 0.8rem 0.8rem 3.2rem;
            background-color: #F5F7F7;
            &::placeholder{
                color: #828C8C;
                font-size: 1.4rem;
            }
            @media(max-width:1200px) {
                padding: 0.8rem 0.8rem 0.8rem 1.6rem;
            }
        }
        .ic-search{
            cursor: pointer;
        }
    }
}

.mg-48{
    margin-bottom: 4.8rem;
    @media (max-width: 1200px) {
        margin-bottom: 2.8rem;
    }
}

.mg-16{
    margin-bottom: 1.6rem;
}

.mg-32{
    margin-bottom: 3.2rem;
    @media (max-width:1200px) {
        margin-bottom: 1.6rem;
    }
}

.mg-24{
    margin-bottom: 2.4rem;
}

.mg-t-24{
    margin-top: 2.4rem;
}

.add-active-js{
    &.active{
        .splitting .char{
            transform: translateX(0);
            opacity: 1;
        }
    }
}
.splitting .char{
    transform: translateX(6rem);
    opacity: 0;
    transition: 0.6s calc(var(--char-index) * 0.02s) ease-in-out;
}

.mona-content > *{
    margin: 0;
    font-family: var(--font-sec);
}

.btn-load{
    font-family: var(--font-sec);
    font-size: 1.6rem;
    font-weight: 600;
    cursor: pointer;
    display: none;
    &.show{
        display: block;
    }
}

.mona-content{
    h1,h2,h3,h4,h5,h6{
        font-weight: 600;
        color: #101212;
        font-family: var(--font-pri) !important;
    }
    h1{
        font-size: 3.2rem;
        // color: #101212;
    }
    p{
        font-size: 1.6rem;
        font-family: var(--font-pri) !important;
    }
    a{
        color: #1D86EF;
        font-weight: 600;
        text-decoration: underline;
    }
    p,ul,a,strong,span{
        font-family: var(--font-pri) !important;
        @media (max-width:1200px) {
            font-size: 1.6rem;
        }
    }
    > *{
        font-family: var(--font-pri) !important;
    }
}

br{
    @media (max-width:575px) {
        display: none;
    }
}

.page-htqt, .page-training-cntt{
    overflow: hidden;
}

.disable {
    opacity: 0.7;
    pointer-events: none;
}


.wpcf7-spinner{
    position: absolute;
    z-index: 3;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: $color-second !important;
}

form.submitting {
    .btn{
        opacity: 0.6;
        pointer-events: none;
    }
}

// .main:not(.page-home){
//     .banner-sect{
//         padding-bottom: var(--ss-pd);
//     }
// }