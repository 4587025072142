.map-wr{
    @include iframePd(608, 1728);
    @include max(575px) {
        min-height: 35rem
    }
    iframe{
        @include iframeCover;
    }
}

.contact-lf{
    @include flexCol;
}

.contact-rt{
    @include max(767px) {
        order: -1;
    }
}

.contact-sect{
    margin-top: -20rem;
    position: relative;
    z-index: 2;
    @include max(1200px) {
        margin-top: -15rem;
    }
    @include max(575px) {
        margin-top: -10rem;
    }
}

.form-ct-box{
    background-color: #fff;
    box-shadow: 0px 4px 35.3px 0px rgba(18, 27, 44, 0.08);
    margin-bottom: var(--it-pd);
    .inner{
        padding: 4rem;
        @include max(1200px) {
            padding: 2rem;
        }
    }
    .form-ct-item{
        display: flex;
        justify-content: space-between;
        align-items: center;
        row-gap: 1.6rem;
        &:first-child{
            padding-bottom: var(--it-pd);
            margin-bottom: var(--it-pd);
            border-bottom: 1px solid #E1E8E8;
        }
        .img{
            width: 8.3rem;
            height: 8.3rem;
            flex-shrink: 0;
            margin-right: 1.6rem;
            @include max(1200px) {
                width: 6rem;
                height: 6rem;
                margin-right: 1rem;
            }
            @include max(575px) {
                width: 4.2rem;
                height: 4.2rem;
            }
            img{
                width: 100%;
                height: 100%;
            }
        }
        .txt{
            font-family: var(--font-sec);
            font-size: 2.4rem;
            font-weight: 500;
            color: $color-third;
            @include max(1620px) {
                font-size: 2rem;
            }
            @include max(1200px) {
                font-size: 1.6rem;
            }
            @include max(575px) {
                font-size: 1.4rem;
            }
        }
        .form-ct-item-lf{
            display: flex;
            align-items: center;
        }
        .form-ct-item-rt{
            .contact-telephone{
            }
            p{
                font-family: var(--font-sec);
                color: #606969;
                font-size: 2rem;
                flex-direction: column;
                justify-content: center;
                display: flex;
                @include max(1200px) {
                    font-size: 1.6rem;
                }
                @include max(575px) {
                    font-size: 1.4rem;
                }
            }
        }
    }
}

.teacher-contact{
    width: calc(10/12*100%);
    margin: 0 auto;
    @include max(991px) {
        width: 100%;
    }
    .inner{
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        // gap: 3.2rem;
        padding: 0;
        background-color: transparent;
        margin: 0 calc(-1 * var(--it-pd));
        row-gap: var(--it-pd);
    }
    .form-ct-box{
        background-color: transparent;
        margin-bottom: 0;
        box-shadow: none;
        .form-ct-item{
            width: 50%;
            padding: 0 var(--it-pd);
            @include max(600px) {
                width: 100%;
            }
            .form-ct-item-inner{
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                background-color: $white;
                flex-wrap: wrap;
                justify-content: space-between;
                padding: 2.4rem;
                @include max(1200px) {
                    padding: 1.6rem;
                }
            }
            &:first-child{
                padding-bottom: 0;
                margin-bottom: 0;
                border-bottom: none;
            }
            .img{
                @include max(991px) {
                    width: 4rem;
                    height: 4rem;
                }
            }
            .txt{
                @include max(1200px) {
                    font-size: 1.6rem;
                }
            }
        }
    }
}

.form-ct{
    background-color: $white;
    box-shadow: 0px 4px 35.3px 0px rgba(18, 27, 44, 0.08);
    .tt-sect{
        margin-bottom: 3.2rem;
        @include max(1200px) {
            margin-bottom: 1.6rem;
        }
    }
    .form-ct-inner{
        padding: 4rem;
        @include max(1200px) {
            padding: 2rem;
        }
    }
}