.ncst-st-sect{
    .value-content-wr {
        .value-content-thumb-wr{
            .img{
                &:last-child{
                    right: auto;
                    left: 0;
                }
                &:first-child{
                    left: auto;
                    right: 0;
                }
                &:nth-child(2){
                    left: auto;
                    right: 0;
                }
            }
        }
    }
}

.student-clb-sect{
    .bg{
        &::after{
            background: linear-gradient(0deg, rgba(0, 34, 68, 0.95) 0%, rgba(0, 34, 68, 0.95) 100%);
        }
    }
}

.alumnus-content{
    position: relative;
    z-index: 2;
    margin-top: -10rem;
    @include max(1200px) {
        margin-top: -7rem;
    }
    @include max(800px) {
        margin-top: 3rem;
    }
}

.alumnus-content-wr{
    .alumnus-content-item{
        width: calc(4/12*100%);
        padding: 0 var(--it-pd);
        @include max(700px) {
            width: 50%;
        }
        @include max(575px) {
            width: 100%;
        }
    }
}