// range slider
.box-range-slider {
	position: relative;
	width: 100%;
}
.wr-box-range-slider {
	height: 100%;
	width: 100%;
	display: flex;
	align-items: center;
	input[type="range"] {
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
		width: 100%;
		outline: none;
		position: absolute;
		margin: auto;
		background-color: transparent;
		pointer-events: none;
	}
	.wr-slider-track {
		width: 100%;
		height: 0.3rem;
		position: relative;
		background-color: #d5d5d5;;
		overflow: hidden;
		border-radius: 0.5rem;
	}
	.slider-track {
		height: 100%;
		background-color: $color-pri;
		position: absolute;
		left: 0;
		right: 0;
		margin: auto;
		border-radius: 0.5rem;
	}
}
input[type="range"] {
	&::-webkit-slider-runnable-track {
		-webkit-appearance: none;
		height: 0.5rem;
	}
	&::-moz-range-track {
		-moz-appearance: none;
		height: 0.5rem;
	}
	&::-ms-track {
		appearance: none;
		height: 0.5rem;
	}
	&::-webkit-slider-thumb {
		-webkit-appearance: none;
		width: 1.6rem;
		height: 1.6rem;
		background-color: $white;
		cursor: pointer;
		pointer-events: auto;
		border-radius: 50%;
		margin-top: -0.5rem;
		border: 0.3rem solid $color-pri;
	}
}
.input[type="range"] {
	&::-moz-range-thumb {
		-webkit-appearance: none;
		width: 1.5rem;
		height: 1.5rem;
		cursor: pointer;
		pointer-events: auto;
		border-radius: 50%;
		background-color: $color-pri;
	}
	&::-ms-thumb {
		appearance: none;
		width: 1.5rem;
		height: 1.5rem;
		cursor: pointer;
		pointer-events: auto;
		border-radius: 50%;
		background-color: $color-pri;
	}
	&:active {
		&::-webkit-slider-thumb {
			background-color: $white;
			border: 0.1rem solid #182951;
		}
	}
}
.wr-range-val {
	margin-bottom: 1rem;
	display: flex;
	justify-content: space-between;
	span {
		font-size: 1.4rem;
		font-weight: 600;
	}
}