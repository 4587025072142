.effectShine{
    position: relative;
    overflow: hidden;
    &::before{
        content: '';
        position: absolute;
        top: 0;
        left: -100%;
        z-index: 2;
        display: block;
        content: '';
        width: 50%;
        height: 100%;
        background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.3) 100%);
        transform: skewX(-25deg);
    }
    &:hover{
        &::before{
            animation: shine 0.8s ease-in forwards;
        }
    }
}


// .career-card{
//     width: 50%;
// }

.heading-sect{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    row-gap: 1.6rem;
    margin-bottom: 3.2rem;
    @include max(1200px) {
        margin-bottom: 1.6rem;
    }
    @include max(575px) {
        text-align: center;
        text-wrap: balance;
        flex-direction: column;
    }
}

.card-slider-heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    row-gap: 1.6rem;
    margin-bottom: 3.2rem;
    @include max(1200px) {
        margin-bottom: 1.6rem;
    }
    @include max(575px) {
        text-align: center;
        text-wrap: balance;
        flex-direction: column;
    }
}

.blog-slider-sect {
    .card-slider-wr {
        .card-slider-block{
            overflow: hidden;
            .swiper{
                margin: 0 calc(-1 * var(--it-pd));
            }
            .swiper-slide{
                width: calc(3/12*100%);
                padding: 0 var(--it-pd);
                @include max(991px) {
                    width: calc(4/12*100%);
                }
                @include max(767px) {
                    width: 50%;
                }
                @include max(390px) {
                    width: 100%;
                }
            }
            .card{
                .desc{
                    display: none;
                }
            }
        }
    }
}


.card-slider-block{
    .container {
        width: calc(100vw - (100vw - 120rem)/2);
        margin: 0 -0.3rem 0 auto;
        max-width: 100%;
        padding: 0;
        overflow: hidden;
        @include max(1200px) {
            width: 100%;
            padding: 0 1.5rem;
            margin: 0 0 0 auto;
        }
    }
    .card {
        height: 100%;
        .info{
            margin-bottom: 0.4rem;
        }
        .tt-card{
            margin-bottom: 1.2rem;
        }
        .link{
            margin-top: auto;
        }
        .desc-card{
            display: none;
        }
    }
    .card-inner{
        height: 100%;
        flex-direction: column;
        display: flex;
    }
    .content-card{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex: 1;
    }
}
.career-slider-sect{
    overflow: hidden;

    .card-slider-wr {
    }
}
.card-slider-block-wr{
    overflow: hidden;
}
.card-slider-block{
    overflow: hidden;
    .swiper{
        margin: 0 calc(-1 * var(--it-pd));
    }
    .swiper-slide{
        width: calc(5/12*100%);
        padding: 0 var(--it-pd);
        @include max(700px){
            width: calc(6/12*100%);
        }
        @include max(575px) {
            width: calc(10/12*100%);
        }
    }
    .card{
        .desc{
            display: none;
        }
    }
}


.box-content {
    .box-content-wr{
        align-items: center
    }
    .thumb-inner{
        @include imgPd(444, 592);
        &:hover{
            img{
                transform: translate(-50%, -50%) scale(1.1);
            }
        }
        img{
            @include imgCover;
            transition: all .3s;
        }
    }
    .content{
        .tt-sect{
            margin-bottom: 1.6rem;
            @include max(575px) {
                text-align: center;
                text-wrap: balance;
            }
        }
        .btn{
            @include max(575px) {
                margin: 0 auto;
            }
        }
        .miss-content{
            position: relative;
            &.hide-content{
                &::after{
                    content: '';
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    width: 100%;
                    height: 5rem;
                    background: linear-gradient(180deg, rgba(255, 255, 255, 0.4) 0%, #FFF 78%);
                }
            }
        }
    }
}


.envir-sect{
    position: relative;
    z-index: 2;
    padding: 5rem 0;
    overflow: hidden;
    .bg{
        position: absolute;
        z-index: -1;
        inset: 0;
        width: 100%;
        height: 100%;
        &::after{
            content: '';
            position: absolute;
            inset: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(0deg, rgba(0, 34, 68, 0.95) 0%, rgba(0, 34, 68, 0.95) 100%);
        }
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .envir-wr{
        width: calc(11/12*100%);
        margin: 0 auto;
        align-items: center;
        @include max(700px) {
            width: 100%;
        }
    }
    .envir-content{
        width: calc(5/11*100%);
        @include max(700px) {
            width: 100%;
            text-align: center;
        }
    }
    .envir-thumb{
        width: calc(6/11*100%);
        @include max(700px) {
            width: 80%;
            margin: 0 auto;
        }
        @include max(575px) {
            width: 100%;
        }
    }
    .tt-sect{
        margin-bottom: 1.6rem;
    }
    .desc{
        font-size: 1.6rem;
    }
    .envir-content-wr{
        padding-right: 1.6rem;
        .tt-sect{
            color: #F5F7F7;
        }
        .desc{
            color: #EDF2F2;
        }
    }
    .envir-thumb-wr{
        position: relative;
        display: block;
        padding-top: calc(572/592*100%);
        overflow: hidden;
        .img{
            position: absolute;
            width: calc(2.8/6*100%);
            &:hover{
                img{
                    transform: scale(1.1);
                }
            }
            &:first-child{
                left: 0;
                top: 50%;
                transform: translate(0, -50%);
                aspect-ratio: 280/360;
            }
            &:nth-child(2){
                top: 0;
                right: 0;
                aspect-ratio: 280/180;
            }
            &:last-child{
                bottom: 0;
                right: 0;
                aspect-ratio: 280/360;
            }
            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
                transition: all .3s;
            }
        }
    }
}

.slider-lg{
    padding: 10rem 0 5rem;
    @include max(1200px) {
        padding: 5rem 0 5rem;
    }

}

.slider-lg-block{
    position: relative;
    z-index: 2;
    overflow: hidden;
    .bg{
        position: absolute;
        z-index: -1;
        inset: 0;
        width: 100%;
        height: 100%;
        &::after{
            content: '';
            position: absolute;
            inset: 0;
            width: 100%;
            height: 100%;
            // background: linear-gradient(0deg, rgba(13, 15, 51, 0.95) 0%, rgba(13, 15, 51, 0.95) 100%);        
            background: linear-gradient(0deg, rgba(0, 34, 68, 0.95) 0%, rgba(0, 34, 68, 0.95) 100%);
        }
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}

.slider-thumb-lg{
    >.container{
        max-width: 160.4rem;
    }
    .slider-lg-wr{
        position: relative;
    }
    .swiper{
        margin: 0 calc(-1 * var(--it-pd));
        padding: 10rem 0;
        // overflow: visible;
        @include max(1200px) {
            padding: 5rem 0;
        }
        @include max(450px) {
            padding: 0;
        }
    }
    .swiper-slide{
        width: calc(4/12*100%);
        padding: 0 var(--it-pd);
        transition: all .4s ease-out;
        @include max(991px) {
            width: calc(6/12*100%);
        }
        @include max(575px) {
            width: calc(8/12*100%);
        }
        @include max(450px) {
            width: calc(12/12*100%);
        }
        &.swiper-slide-active{
            // width: calc(5.5/12*100%);
            transform: scale(1.2);
            @include max(650px) {
                transform: scale(1);
            }
            .img{
                .content{
                    transform: translate(0, 0);
                    visibility: visible;
                }
            }
        }
        &:not(.swiper-slide-active){
            transform: scale(0.8);
            @include max(650px) {
                transform: scale(1)
            }
        }
        >.img{
            @include imgPd(421, 384);
            transition: all .4s ease-out;
            @include max(500px) {
                min-height: 40rem; 
            }
            img{
                @include imgCover;
            }
            .content{
                position: absolute;
                width: 100%;
                left: 0;
                bottom: 0;
                background-color: $color-third;
                transform: translate(0, 100%);
                visibility: hidden;
                transition: all .3s;
                .content-inner{
                    @include flexCol;
                    padding: 4rem;
                    @include max(1650px) {
                        padding: 2rem;
                    }
                    @include max(991px) {
                        padding: 1.4rem;
                    }
                }
                .tt-card{
                    margin-bottom: 0.6rem;
                    @include max(991px) {
                        font-size: 1.8rem;
                        line-height: 100%;
                    }
                }
                .txt{
                    color: #EDF2F2;
                    font-size: 1.4rem;
                    @include max(1024px) {
                        font-size: 1.2rem;
                    }
                }
            }
        }
    }
    .box-navi{
        .btn-navi.prev{
            @include max(1650px) {
                left: 0;
            }
        }
        .btn-navi.next{
            @include max(1650px) {
                right: 0;
            }
        }
    }
}
.slider-lg-block {
    .slider-lg-heading-wr{
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 1.6rem;
        // margin-bottom: 4.8rem;
        @include max(1200px) {
            margin-bottom: 2.8rem;
        }
        @include max(700px) {
            flex-wrap: wrap;
            justify-content: center;
            text-align: center;
        }
        .tt-sect{
            color: $white-pri;
        }
        .desc{
            font-size: 1.6rem;
            color: #EDF2F2;
            width: calc(5/12*100%);
            @include max(1024px){
                width: 50%;
            }
            @include max(700px) {
                width: 100%;
            }
        }
    }
}

.page-trainning, .page-dang-bo-hoc-vien{
    .slider-thumb-lg {
        .swiper-slide {
            >.img {
                .content{
                    .content-inner{
                        .stt{
                            order: 2;
                        }
                    }
                }
            }
        } 
    } 
}

.intro-vaa {
    overflow: hidden;
    .slider-lg-block{
        position: relative;
        z-index: 2;
        .bg{
            &::after{
                background: linear-gradient(0deg, rgba(0, 34, 68, 0.95) 0%, rgba(0, 34, 68, 0.95) 100%);
            }
        }
    }
    .slider-lg{
        padding: 10rem 0;
        @include max(1200px) {
            padding: 5rem 0;
        }
    }

    .slider-lg-student{
        .swiper{
            margin: 0 calc(-1 * var(--it-pd));
        }
        .swiper-wrapper{
            transition-timing-function: linear !important;
        }
        .swiper-slide{
            width: 17%;
            padding: 0 var(--it-pd);
            @include max(991px) {
                width: 23%;
            }
            @include max(700px) {
                width: 30%;
            }
            @include max(575px) {
                width: 36%;
            }
            .img{
                width: 100%;
                height: 100%;
                @include flexCol;
                justify-content: center;
            }
            .img-inner{
                img{
                    @include imgCover;
                }
            }
            &:nth-child(odd) {
                .img-inner{
                    @include imgPd(361, 280);
                }
            }
            &:nth-child(even){
                .img-inner{
                    @include imgPd(256, 280);
                }
            }
        }
        .ach-block {
            margin-top: 4.8rem
        }
    
    }
}

.intro-vaa .slider-lg-heading{
    margin-bottom: 4.8rem;
    @include max(1200px) {
        margin-bottom: 2.8rem;
    }
}

.ach-list{
    @include row(1.6);
    @include max(1200px) {
        margin: 0 calc(-1 * var(--it-pd));
    }
    .ach-item{
        position: relative;
        text-align: center;
        width: calc(3/12*100%);
        padding: 0 var(--it-pd);
        @include max(800px) {
            width: 50%;
        }
        &:not(:last-child) {
            &::after{
                content: '';
                position: absolute;
                width: 1px;
                height: 35%;
                top: 50%;
                right: 0;
                transform: translate(0, -50%);
                background-color: $white;
            }
        }
        &:nth-child(2) {
            &::after{
                @include max(800px) {
                    display: none;
                }
            }
        }
        .inner{
            padding: 2.4rem 4rem;
            @include max(1200px) {
                padding: 2rem;
            }
            @include max(991px) {
                padding: 1rem;
            }
        }
    }
    .ach{
        color: $white;
        font-family: var(--font-sec);
        font-size: 6.4rem;
        font-weight: 500;
        line-height: 150%;
        @include max(1200px) {
            font-size: 5rem;
        }
        @include max(991px) {
            font-size: 4rem;
        }
        @include max(575px) {
            font-size: 3rem;
        }
    }
    .txt{
        color: #F5F6F7;
        font-weight: 500;
        line-height: 150%;
        @include max(1200px) {
            font-size: 1.6rem;
        }
        @include max(575px) {
            font-size: 1.4rem;
        }
    }
}

.form-group{
    .wpcf7-form-control-wrap{
        width: 100%;
    }
    .input{
        position: relative;
        display: flex;
        align-items: center;
        width: 100%;
        height: 4.5rem;
        background-color: #F5F7F7;
        padding: 0 1.6rem;
        input{
            width: 100%;
            height: 100%;
            padding: 1.2rem 0;
            background-color: #F5F7F7;
            &::placeholder{
                font-family: var(--font-sec);
                color: #828C8C;
                font-size: 1.4rem;
            }
        }
        .select2-container .select2-selection--single{
            padding: 0;
        }
        .select2-container .select2-selection__arrow{
            background-image: url(/template/assets/images/arr-input.png);
        }
        >.ic{
            position: absolute;
            right: 1.6rem;
            top: 50%;
            transform: translate(0, -50%);
            width: 2.4rem;
            height: 2.4rem;
            pointer-events: none;
            cursor: pointer;
            @include max(1200px) {
                width: 2rem;
                height: 2rem;
            }
            img{
                width: 100%;
                height: 100%;
            }
        }
    }
    .textarea{
        width: 100%;
        height: 15.5rem;
        background-color: #F5F7F7;
        padding: 0 1.6rem;
        textarea{
            width: 100%;
            height: 100%;
            background-color: #F5F7F7;
            resize: none;
            padding: 1.2rem 0;
            &::placeholder{
                font-family: var(--font-sec);
                color: #828C8C;
                font-size: 1.4rem;
            }
        }
    }
    .label-group{
        font-family: var(--font-sec);
        font-size: 1.4rem;
        color: $color-third;
        font-weight: 500;
        margin-bottom: 0.4rem;
    }
    .btn{
        width: 100%;
    }
    .input-file{
        width: 100%;
        // height: 9.4rem;
        border-radius: 0.8rem;
        border: 1px dashed #A3A3A3;
        padding: 1.6rem;
        .wpcf7-form-control-wrap{
            display: none;
        }
    }
    .txt-down{
        font-family: var(--font-sec);
        color: #828C8C;
        font-size: 1.2rem;
    }
    .label-file{
        display: flex;
        justify-content: center;
        cursor: pointer;
    }
    .file-content{
        font-family: var(--font-sec);
        text-align: center;
        min-height: 9.4rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .ic{
            display: flex;
            width: 1.8rem;
            height: 2.2rem;
            margin: 0 auto 2rem;
            img{
                width: 100%;
                height: 100%;
            }
        }
        .txt{
            font-size: 1.4rem;
            font-weight: 600;
            color: $color-third;
            display: block;
            margin-bottom: 0.8rem;
        }
        .note{
            color: #A1ABAB;
            font-size: 1.2rem;
        }
    }
}

.level-admis{
    margin-top: 3.2rem;
    .tt-sect{
        padding-bottom: 1.6rem;
        margin-bottom: 1.6rem;
        border-bottom: 1px solid #ECEEF4;
    }
    .level-admis-item{
        margin-bottom: 1.6rem;
        &.active{
            .level-admis-link{
                color: $color-pri;
                font-weight: 600;
            }
        }
    }
    .level-admis-link{
        font-size: 2rem;
        line-height: 150%;
        display: flex;
        padding-bottom: 1.6rem;
        border-bottom: 1px solid #ECEEF4;
        transition: all .3s;
        @include max(1200px) {
            font-size: 1.4rem;
        }
        &:hover{
            color: $color-pri;
        }
    }
}

.level-admis-list{

}

.search-certi-form-inner{
    background: #FFF;
    box-shadow: 0px 4px 35.3px 0px rgba(18, 27, 44, 0.08);
    .search-certi-form-wr{
        width: calc(10/12*100%);
        margin: 0 auto;
        padding: 10rem 0;
        @include max(1200px) {
            padding: 5rem 0;
        }
    }
}

.info-curri-dt{
    .mona-content{
        a{
            text-decoration: none;
        }
    }
}
.box-noti{
    border-left: 0.4rem solid $color-pri;
    background: #FFF;
    box-shadow: 0px 4px 35.3px 0px rgba(18, 27, 44, 0.08);
    margin-top: 3.2rem;
    @include max(1200px) {
        margin-top: 1.6rem;
    }
}
.box-noti-inner{
    padding: 1.6rem;
}

.table-sect{
    .container{
        max-width: 155.8rem;
    }
    .table-heading{
        background-color: $color-pri;
        .table-item{
            color: #F5F7F7;
            font-weight: 700;
            text-transform: uppercase;
        }
    }
    .table-item{
        width: 7.8%;
        font-size: 1.6rem;
        text-align: left;
        padding: 0 0.4rem;
        &:first-child{
            width: 15%;
        }
        &:nth-child(5){
            width: 15%;
        }
        &:nth-child(6){
            width: 15%;
        }
    }
    .table-row-inner {
        padding: 0.8rem 2.4rem;
    }
    .table-row{
        display: flex;
        align-items: center;
        margin: 0 -0.4rem;
    }
    .table-body{
        font-family: var(--font-sec);
        background-color: $white;
    }
}

.majors-list{
    .majors-card{
        @include max(500px) {
            width: 100%;
        }
    }
}


.ad-mis-box{
    position: relative;
    z-index: 2;
    flex: 1;
    .bg{
        position: absolute;
        z-index: -1;
        inset: 0;
        width: 100%;
        height: 100%;
        &::after{
            content: '';
            position: absolute;
            inset: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(0deg, rgba(0, 34, 68, 0.95) 0%, rgba(0, 34, 68, 0.95) 100%);
        }
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .content{
        padding: 0rem 4rem;
        @include flexCol;
        justify-content: center;
        height: 100%;
        @include max(1200px) {
            padding: 0rem 2rem;
        }
        @include max(767px) {
            text-align: center;
            padding: 5rem;
        }
        @include max(575px) {
            padding: 4rem 2rem;
        }
        .stt{
            color: #F5F6F7;
            font-weight: 700;
            font-size: 1.6rem;
            line-height: 150%;
            text-transform: uppercase;
        }
        .tt-sect{
            color: #F5F6F7;
            margin-bottom: 1.2rem;
        }
        .desc{
            font-size: 1.6rem;
            color: #EDEFF2;
            margin-bottom: 2.4rem;
        }
        .btn{
            @include max(767px) {
                margin: 0 auto;
            }
        }
    }
}

.table-info{
    box-shadow: 0px 4px 35.3px 0px rgba(18, 27, 44, 0.08);
}


.table-info-head{
    background-color: $color-pri;
    .table-info-item{
        @include max(575px) {
            width: 100%;
        }
        .txt{
            color: #F5F7F7;
            font-weight: 700;
        }
    }
}

.table-info-row-inner{
    padding: 1.4rem 0.8rem 1.4rem 1.4rem;
    display: flex;
    flex-wrap: wrap;
    margin: 0 -0.8rem;
}

.table-info-item{
    font-size: 1.6rem;
    line-height: 150%;
    width: 50%;
    padding: 0 0.8rem;
    @include max(575px) {
        font-size: 1.4rem;
    }
}

.table-info-body{
    font-family: var(--font-sec);
    background-color: $white;
    .table-info-row{
        &:not(:last-child){
            border-bottom: 1px solid #D1D1D1;
        }
    }
    .table-info-item{
        color: $color-third;
        font-weight: 600;
        &:not(:last-child){
            position: relative;
            &::after{
                content: '';
                position: absolute;
                right: 0;
                top: 50%;
                transform: translate(0,-50%);
                width: 1px;
                height: 150%;
                background-color: #EDF2F2;
                @include max(575px) {
                    height: 100%;
                }
            }
        }
        &:first-child{
            font-weight: 400;
        }
    }
}

.thumb-box-content-sect{
    overflow: hidden;
    .thumb-box-content-heading {
        width: calc(8/12*100%);
        margin: 0 auto 4.8rem;
        // margin: 0 auto;
        @include max(700px) {
            width: 100%;
        }
        @include max(1200px) {
            margin: 0 auto 2.8rem;
        }
        .tt-sect{
            margin-bottom: 1.2rem
        }
        .desc{
            @include max(1620px) {
                font-size: 1.6rem;
            }
            @include max(1200px) {
                font-size: 1.4rem;
            }
        }
    }

    .thumb-box-content-main{
        .swiper{
            padding-top: 8rem;
            @include max(700px) {
                padding-top: 3rem;
            }
        }
        .swiper-slide{
            transition: transform 0.6s, opacity 0.6s;

            &.swiper-slide-active{
                @include min(700px) {
                }
                    .thumb-box-content-box{
                        transform: translate(0,0);
                        opacity: 1;
                        visibility: visible;
                    }
            }
            &:not(.swiper-slide-active) {
                opacity: 0.9;
                transform: scale(0.9);
                @include min(700px) {
                }
            }
        }

        .box-navi{
            .btn-navi{
                top: calc(50% + 4rem);
                @include max(700px) {
                    top: calc(calc((685 / 1216)*100% /2));
                }
                &.prev{
                    left: 0;
                }
                &.next{
                    right: 0;
                }
            }
        }
        .thumb-box{
            position: relative;
            .thumb-box-content-box{
                @include min(700px) {
                    width: calc(6/12*100%);
                    position: absolute;
                    z-index: 2;
                    left: 0;
                    bottom: -0.1rem;
                    pointer-events: none;
                }
                @include max(700px){
                    margin-top: 3rem
                }
            }
        }
        .thumb-inner{
            @include imgPd(685, 1216);
            z-index: 2;
            overflow: visible;
            // &::before, &::after{
            //     content: '';
            //     z-index: -1;
            //     height: 100%;
            //     position: absolute;
            //     background-color: #D9D9D9;
            //     opacity: 0.5;
            //     left: 50%;
            //     transform: translate(-50%,0);
            //     pointer-events: none;
            // }
            // &::before{
            //     // z-index: -2;
            //     width: calc(8/12*100%);
            //     top: -8rem;
            //     @include max(1200px) {
            //         top: -4rem;
            //     }
            // }
            // &::after{
            //     // z-index: -1;
            //     width: calc(10/12*100%);
            //     top: -4rem;
            //     @include max(1200px) {
            //         top: -2rem;
            //     }
            // }
            img{
                @include imgCover;
            }
        }
        .thumb-box-content-box{
            transform: translate(0,100%);
            opacity: 0;
            visibility: hidden;
            pointer-events: none;
            transition: all .5s;
            @include min(700px) {
            }
        }
        .btn{
            margin: 4.8rem auto 0;
        }
    }

    .content-box-list{
        @include min(1024px) {
            position: relative;
            z-index: 2;
            margin-top: -15rem;
        }
    }

    // .content-card-box{
    //     width: calc(4/12*100%);
    //     padding: 0 var(--it-pd);
    // }
}

.page-about, .page-nckh{
    .content-card-box{
        background-color: $white;
        box-shadow: 0px 4px 35.3px 0px rgba(18, 27, 44, 0.08);
    }
}

.vd-inner{
    @include imgPd(972,1728);
    &::after{
        content: '';
        position: absolute;
        inset: 0;
        width: 100%;
        height: 100%;
        background: rgba(13, 15, 51, 0.30);
    }
    img{
        @include imgCover
    }
}

.btn-play {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    width: 6.4rem;
    height: 6.4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    @include max(1200px) {
        width: 4rem;
        height: 4rem;
    }
    // @include max(575px) {
    //     width: 6rem;
    //     height: 6rem;
    // }
    // @include max(360px) {
    //     width: 4.6rem;
    //     height: 4.6rem;
    // }
    &::before, &::after{
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        top: 0rem;
        left: 0rem;
        position: absolute;
        z-index: -1;
        border-radius: 50%;
        background-color: $white;
        animation: scaleAni 1.8s linear infinite;
        opacity: 0;
    }
    &::after{
        animation-delay: 0.5s;
    }
    i{
        color: $color-pri;
        font-size: 2.2rem;
        @include max(1200px) {
            font-size: 1.8rem;
        }
        // @include max(575px) {
    
            //     font-size: 2.2rem;
        // }
    }
}

.teacher-contact-wr{
    margin-top: 3.5rem;
}

.blog-govern {
    .blog-filter-wr{
        font-family: var(--font-sec);
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        >.txt{
            font-size: 1.4rem;
        }
        .box-search{
            width: calc(4/12*100%);
            @include max(575px) {
                width: 100%;
            }
        }
    }
    .blog-list-sect{
        .blog-lf{
            .thumb-card{
                width: calc(4/12*100%);
                @include max(575px) {
                    width: 100%;
                }
            }
        }
    }
}

.rvw-card{
    height: 100%;
    .rvw-card-inner{
        padding: 2.4rem;
        background-color: $white;
        box-shadow: 0px 4px 10px 0px rgba(8, 13, 20, 0.05);
        height: 100%;
        @include max(1200px) {
            padding: 1.4rem;
        }
    }
    .rvw-card-wr{
        display: flex;
        @include max(700px) {
            flex-direction: column;
            row-gap: 1rem;
        }
    }
    .ava{
        width: 8rem;
        height: 8rem;
        flex-shrink: 0;
        border-radius: 50%;
        overflow: hidden;
        margin-right: 1.6rem;
        @include max(1620px) {
            width: 6.8rem;
            height: 6.8rem;
        }
        @include max(1200px) {
            margin-right: 0.8rem;
            width: 4.8rem;
            height: 4.8rem;
        }
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .tt-card.third{
        color: $color-third;
        font-weight: 600;
    }
    .position{
        display: block;
        font-size: 1.4rem;
        margin-bottom: 1.2rem;
        @include max(1200px) {
            font-size: 1.2rem;
        }
    }
    .desc{
        font-size: 1.6rem;
        margin-bottom: 2.4rem;
        @include max(1200px) {
            margin-bottom: 1.2rem;
        }
        @include max(575px) {
            font-size: 1.4rem;
        }
    }
}

.person-content{
    font-size: 1.2rem;
    color: #EDF2F2;
    li{
        position: relative;
        display: flex;
        &::before{
            content: '';
            display: block;
            width: 0.4rem;
            height: 0.4rem;
            border-radius: 50%;
            background-color: #EDF2F2;
            flex-shrink: 0;
            margin-right: 1rem;
            margin-top: 1rem;
        }
    }
}

.miss-content{
    color: $color-text;
    ul{
        li{
            position: relative;
            display: flex;
            &::before{
                content: '';
                display: block;
                width: 0.4rem;
                height: 0.4rem;
                border-radius: 50%;
                background-color: $color-text;
                flex-shrink: 0;
                margin-right: 1rem;
                margin-top: 1rem;
            }
        }
    }
}

.interna-content-box{
    width: calc(10/12*100%);
    display: flex;
    align-items: flex-start;
    margin-bottom: 6.2rem;
    @include max(767px) {
        width: 100%;
    }
    @include max(650px) {
        flex-direction: column;
    }
    &:nth-child(2) {
        margin-left: calc(1/12*100%);
        @include max(1480px) {
            margin-left: calc(0.5/12*100%);
        }
        @include max(1200px) {
            margin-left: calc(1/12*100%);
        }
        @include max(767px) {
            margin-left: 0;
        }
    }
    &:last-child{
        margin-bottom: 0;
        margin-left: auto;
        @include max(1480px) {
            margin-right: calc(0.5/12*100%);
        }
        @include max(1200px) {
            margin-left: auto;
            margin-right: 0;
        }
        @include max(767px) {
            margin-right: 0;
        }
    }
    .img{
        width: calc(2/10*100%);
        flex-shrink: 0;
        @include max(1200px) {
            width: calc(4/12*100%);
        }
        @include max(650px) {
            width: 60%;
            margin: 0 auto;
        }
        @include max(390px) {
            width: 100%;
        }
        .img-inner{
            @include imgPd(224, 224);
            height: 100%;
            border-radius: 1.2rem 0 0 1.2rem;
            @include max(650px) {
                border-radius: 1.2rem 1.2rem 0 0
            }
            img{
                @include imgCover;
            }
        }
    }
    .content{
        position: relative;
        flex: 1;
        background-color: $white;
        box-shadow: 0px 4px 10px 0px rgba(8, 13, 20, 0.05);
        .content-inner {
            padding: 4rem;
            height: 100%;
            @include max(1200px) {
                padding: 2rem;
            }
        }
        .decor {
            position: absolute;
            right: 0;
            bottom: 0;
            width: calc(3/10*100%);
            transform: translate(30%, 50%);
            @include min-max(1999px, 2220px) {
                transform: translate(0, 50%);
            }
            @include max(1480px) {
                transform: translate(20%,50%);
            }
            @include max(1200px) {
                transform: translate(0,50%);
            }
            .decor-inner{
                display: block;
                padding-bottom: calc(84/280*100%);
                position: relative;
                overflow: hidden;
                img{
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
}

.page-doan-thanh-nien{
    .mission-main-wr{  
        .box-content{
            &:nth-child(odd) {
                .box-content-wr{
                    flex-direction: row;
                }
            }
            &:nth-child(even) {
                .box-content-wr{
                    flex-direction: row-reverse;
                }            
            }
        }
    }
}
.page-dao-tao-quoc-te{
    .value-content-wr{
        .value-content-thumb{
            order: 2;
        }
    }
}


// stt ở dưới 
.page-doan-thanh-nien, .page-cong-doan-hoc-vien, .page-dao-tao-quoc-te{
    .slider-thumb-lg {
        .swiper-slide {
            >.img {
                .content {
                    .content-inner{
                        flex-direction: column-reverse;
                    }
                }
            } 
        }
    }
}

// tài nguyên số, tạp chí

.page-template-tainguyen-template{
    .hd-up {
        .hd-up-news {
            .hd-up-news-link{
                color: #B3995D;
                font-weight: 700;
            }
        }
    }
}


