.content-card-box{
    background-color: #F5F7F7;
    height: 100%;
    .content-card-box-inner {
        height: 100%;
        padding: calc(var(--it-pd)*2);

    }
    .tt-card.third{
        font-weight: 600;
        color: $color-third;
        margin-bottom: 1.6rem;
    }
    .desc{
        font-size: 1.6rem;
    }
}