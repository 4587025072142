.tt-sect{
    font-family: var(--font-sec);
    font-weight: 600;
    color: $color-third;
    line-height: 120%;
    &.tt-main{
        font-size: 6.4rem;
        color: #F5F6F7;
        @include max(1620px) {
            font-size: 5.6rem
        }
        @include max(1200px) {
            font-size: 4.2rem
        }
        @include max(767px) {
            font-size: 2.8rem
        }
    }
    
    &.pri{
        @include fz-40;

    }

    &.second{
        @include fz-48;
    }

    &.third{
        @include fz-36;
    }

    &.four{
        @include fz-32;
    }

    &.five{
        @include fz-24;
    }
}

.tt-card{
    font-family: var(--font-sec);
    transition: all .3s;
    &.pri{
        color: $color-third;
        @include fz-16;
        font-weight: 700;
        line-height: 150%;
        @include lc(3);
    }
    &.second{
        color: $color-third;
        @include fz-32;
        font-weight: 600;
        line-height: 150%;
        @include lc(3);
    }
    &.third{
        color: #F5F7F7;
        @include fz-24;
        font-weight: 500;
        line-height: 150%;
    }
}

.t-center {
    text-align: center;
    @include max(575px) {
        text-wrap: balance;
    }
}
.p-center{
    margin: 0 auto;
}

.fw-1 {
    font-weight: 100;
}

.fw-2 {
    font-weight: 200;
}

.fw-3 {
    font-weight: 300;
}

.fw-5 {
    font-weight: 500;
}

.fw-6 {
    font-weight: 600;
}

.fw-7 {
    font-weight: 700;
}

.fw-8 {
    font-weight: 800;
}

.fw-b {
    font-weight: bold;
}

.fsi {
    font-style: italic;
}

.t-up {
    text-transform: uppercase;
}

.t9 {
    @include fz-9
}

.t11 {
    @include fz-11
}

.t12 {
    @include fz-12
}

.t14 {
    @include fz-14
}

.t16 {
    @include fz-16
}

.t18 {
    @include fz-18
}

.t20 {
    @include fz-20
}

.t24 {
    @include fz-24
}

.t32 {
    @include fz-32
}

.t40 {
    @include fz-40
}

.t48 {
    @include fz-48
}

.t56 {
    @include fz-56
}

.t64 {
    @include fz-64
}

.t72 {
    @include fz-72
}