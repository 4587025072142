.career-apply{
    @include min(850px) {
        position: sticky;
        top: var(--size-hd);
        height: 100%;
    }
    @include max(850px) {
        position: unset;
        width: 60%;
        margin: 0 auto;
        order: 2;
    }
    @include max(600px) {
        width: 100%;
    }

    .career-apply-wr{
        background-color: #F5F7F7;
        padding: 2.4rem;
        .tt-sect{
            margin-bottom: 1.2rem;
        }
        .desc{
            color: #606969;
            font-size: 1.6rem;
            margin-bottom: 2.4rem;
        }
    }
}

.career-content{
    @include max(850px) {
        width: 100%;
    }
    .mona-content{
        h1, h2, h3, h4, h5, h6{
            color: #101212;
            font-family: var(--font-sec);
        }
        h2{
            font-size: 3.2rem;
            color: #101212;
            font-weight: 600;
            @include max(1200px) {
                font-size: 2.8rem;
            }
        }
        ul{
            color: #101212;
            list-style: none;
            padding-left: 0;
            flex-direction: column;
            display: flex;
            row-gap: 1.6rem;
            @include max(575px) {
                font-size: 1.6rem;
            }
            li{
                display: flex;
                &::before{
                    content: '';
                    background-image: url(/template/assets/images/tick.svg);
                    background-repeat: no-repeat;
                    margin-right: 1.6rem;
                    width: 2.4rem;
                    height: 2.4rem;
                    flex-shrink: 0;
                    @include max(575px) {
                        margin-right: 1rem;
                    }
                }
            }
        }
    }
}