.nav-social{
}
.social-list{
    display: flex;
    align-items: center;
    gap: 1.2rem;
    .social-link{
        display: block;
        width: 3.7rem;
        height: 3.7rem;
        transition: 0.3s linear;
        img{
            transition: 0.4s ease-out;
        }
    }
}
.social-item:hover{
    .social-link{
        img{
            filter: brightness(0) saturate(100%)
        }
    }
}