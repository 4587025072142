// .career-list-row{
//     .career-card{
//         width: 50%;
//         @include max(575px) {
//             width: 100%;
//         }
//     }
// }

.career-card{
    height: 100%;
    .career-card-inner{
        position: relative;
        background-color: #F5F7F7;
        padding: 3.2rem;
        height: 100%;
        transition: all .3s;
        @include max(1200px) {
            padding: 2rem;
        }
        &::before{
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 2px;
            height: 0%;
            background-color: $color-pri;
            transition: all .3s;
        }
        &:hover{
            background-color: $white;
            box-shadow: 0px 4px 32px 0px rgba(18, 27, 44, 0.05);
            &::before{
                height: 100%;
            }
        }
    }
    .tt-card{
        margin-bottom: 1.2rem;
    }
    .desc{
        font-size: 1.6rem;
        @include lc(4);
        @include max(575px) {
            font-size: 1.4rem;
        }
    }
    .info-career{
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin-bottom: 1.6rem;
        row-gap: 1.6rem;
    }
    .info-career-item{
        color: #606969;
        font-size: 1.6rem;
        line-height: 100%;
        @include max(1200px) {
            font-size: 1.2rem;
        }
        &:first-child{
            padding-right: 0.8rem;
            border-right: 1px solid #101212;
        }
        &:last-child{
            margin-left: 0.8rem;
        }
    }
    .link{
        margin-top: 3.5rem;
        display: flex;
        align-items: center;
        color: $color-pri;
        font-weight: 600;
        font-size: 1.4rem;
        @include max(1200px) {
            margin-top: 1.5rem
        }
        .ic{
            @include icon(1.6, 1.6);
            margin-left: 0.8rem;
            img{
                width: 100%;
                height: 100%;
            }
        }
    }
    .career-card-link{
        position: absolute;
        width: 100%;
        height: 100%;
        inset: 0;
        @include min(1200px) {
            display: none;
        }
    }
}