.structure-sect{
    background-color: #f6f6f6;
    .heading-sect{
        justify-content: center;
        width: calc(7/12*100%);
        margin: 0 auto 4.8rem;
        @include max(1200px) {
            margin: 0 auto 2.8rem;
        }
        @include max(700px) {
            width: 100%;
        }
        .desc{
            @include max(1620px) {
                font-size: 1.6rem
            }
            @include max(575px) {
                font-size: 1.4rem
            }
        }
    }
    .structure-img {
        @include imgPd(520, 1216);
        img{
            @include imgCover;
        }
    }
}

.his-slider{
    @include max(700px) {
        row-gap: 5rem;
    }
}

.his-slider-time-wr{
    position: relative;
    @include max(1200px) {
        width: calc(3/12*100%);
    }
    @include max(700px) {
        width: 100%;
    }
    .box-navi{
        position: absolute;
        width: max-content;
        height: 8rem;
        top: 0;
        left: -5rem;
        @include max(1340px) {
            left: 0;
            top: -5rem;
        }
        @include max(700px) {
            width: 8rem;
            height: auto;
            left: 50%;
            transform: translate(-50%, 0);
            top: auto;
            bottom: -1rem;
        }
        .btn-navi{
            transform: translate(0,0);
            .ic{
                width: 2.4rem;
                height: 2.4rem;
                @include max(700px) {
                    transform: rotate(-90deg);
                }
            }
            &.prev{
                left: 0;
                top: 0;
            }
            &.next{
                right: auto;
                left: 0;
                bottom: 0;
                @include max(700px) {
                    left: auto;
                    right: 0;
                }
            }
        }
    }
}

.his-slider-time{
    overflow: hidden;
    height: 100%;
    .swiper{
        overflow: unset;
        @include max(700px) {
            margin: 0 -0.8rem;
        }
    }
    .swiper-slide{
        &.swiper-slide-thumb-active{
            .box-num{
                .num{
                    font-size: 6.4rem;
                    color: $color-pri;
                    line-height: 130%;
                    @include max(1200px) {
                        font-size: 4.8rem;
                    }
                    @include max(700px) {
                        font-size: 3.6rem;
                    }
                    @include max(575px) {
                        font-size: 2.4rem;
                    }
                }
                &::after{
                    width: 100%;
                }
            }
        }
    }
    .box-num{
        position: relative;
        font-family: var(--font-sec);
        &::after{
            content: '';
            position: absolute;
            height: 2px;
            width: 0;
            background-color: $color-pri;
            bottom: 0;
            left: 50%;
            transform: translate(-50%, 0);
            transition: all .3s ease-in;
        }
        &:first-child{
            .num{
                color: rgba(16, 18, 18, var(--opa));
                transform: scale(var(--sl));
            }
        }
        &:nth-child(2){
            .num{
                color: rgba(16, 18, 18, var(--opa));
                transform: scale(var(--sl));
            }
        }
        &:nth-child(3){
            .num{
                color: rgba(16, 18, 18, var(--opa));
                transform: scale(var(--sl));
            }
        }
        &:nth-child(4){
            .num{
                color: rgba(16, 18, 18, var(--opa));
                transform: scale(var(--sl));
            }
        }
        &:nth-child(5){
            .num{
                color: rgba(16, 18, 18, var(--opa));
                transform: scale(var(--sl));
            }
        }
        &:nth-child(6){
            .num{
                color: rgba(16, 18, 18, var(--opa));
                transform: scale(var(--sl));
            }
        }
        &:nth-child(7){
            .num{
                color: rgba(16, 18, 18, var(--opa));
                transform: scale(var(--sl));
            }
        }
        &:nth-child(8){
            .num{
                color: rgba(16, 18, 18, var(--opa));
                transform: scale(var(--sl));
            }
        }
        &:nth-child(9){
            .num{
                color: rgba(16, 18, 18, var(--opa));
                transform: scale(var(--sl));
            }
        }
        &:nth-child(10){
            .num{
                color: rgba(16, 18, 18, var(--opa));
                transform: scale(var(--sl));
            }
        }
        &:last-child{
            .num{
                color: rgba(16, 18, 18, var(--opa));
                transform: scale(var(--sl));
            }
        }
        .num{
            font-size: 6.4rem;
            font-weight: 600;
            line-height: 130%;
            transition: all .4s;
            display: block;
            // color: rgba(16, 18, 18, var(--opa));
            // transform: scale(var(--sl));
            transform-origin: left;
            @include max(1200px) {
                font-size: 4.8rem;
            }
            @include max(700px) {
                font-size: 3.6rem;
                transform: scale(1);
                transform-origin: center;
            }
            @include max(575px) {
                font-size: 2.4rem;
            }
        }
    }
    .swiper-slide{
        width: fit-content !important;
        height: fit-content !important;
        @include min(700px) {
            text-align: left;
            padding-bottom: 3.2rem;
        }
        @include max(701px) {
            // width: calc(3/12*100%);
            padding: 0 0.8rem;
        }
    }
    .swiper-wrapper{
        @include min(700px) {
            flex-direction: column;
            justify-content: space-between;
        }
        @include max(700px) {
            height: auto !important;
        }
    }
}

.quotes-slider {
    .swiper-slide{
        transform: scale(1);
        overflow: hidden;
        &.swiper-slide-active{
            .content-quotes-block{
                .content-quotes-up, .content-quotes-bot{
                    opacity: 1;
                    visibility: visible;
                    transform: translateY(0);
                }
            }
            .bg{
                img{
                    transform: scale(1.15);
                }
            }
            .quotes-content-wr{
                .ic{
                    transform: scale(1);
                }
            }
        }
    }
    .bg{
        position: absolute;
        z-index: -1;
        inset: 0;
        width: 100%;
        height: 100%;
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
            transform: scale(1);
            transition: all 15s linear;
        }
    }
    .quotes-content{
        padding: 10rem 0;
        @include max(575px) {
            padding: 5rem 0;
        }
    }
    .quotes-content-wr{
        display: flex;
        @include max(575px) {
            flex-direction: column
        }
        .ic{
            @include icon(10, 10);
            margin-top: 2.2rem;
            transform: scale(0);
            transition: all .6s;
            transition-delay: .6s;
            @include max(1620px) {
                width: 8rem;
                height: 8rem;
            }
            @include max(1200px) {
                width: 6.8rem;
                height: 6.8rem;
            }
            @include max(575px) {
                margin-top: 0;
            }
            img{
                width: 100%;
                height: 100%;
            }
        }
        .content-quotes-block{
            @include flexCol;
            .content-quotes-up{
                display: flex;
                padding-left: 10rem;
                margin-bottom: 2.2rem;
                transform: translateY(4rem);
                opacity: 0;
                visibility: hidden;
                transition: all .6s;
                transition-delay: .6s;
                @include max(1620px) {
                    padding-left: 8rem;
                }
                @include max(1200px) {
                    padding-left: 5rem;
                }
                @include max(575px) {
                    padding-left: 0
                }
            }
            .ava{
                @include icon(10, 10);
                margin-right: 2.4rem;
                border-radius: 50%;
                @include max(1620px) {
                    width: 8rem;
                    height: 8rem;
                }
                @include max(1200px) {
                    width: 7rem;
                    height: 7rem;
                }
                @include max(575px) {
                    margin-right: 1.2rem;
                }
                img{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
            .quotes-author{
                .tt-sect{
                    margin-bottom: 0.4rem;
                    color: $white;
                }
                .txt{
                    color: $white;
                    font-size: 2.4rem;
                    @include max(1200px) {
                        font-size: 2rem;
                    }
                    @include max(575px) {
                        font-size: 1.6rem;
                    }
                }
            }
            .content-quotes-bot {
                transform: translateY(4rem);
                opacity: 0;
                visibility: hidden;
                transition: all .6s;
                transition-delay: .8s;
                .desc{
                    color: #F5F7F7;
                    font-size: 2.4rem;
                    font-weight: 500;
                    @include max(1620px) {
                        font-size: 2rem;
                    }
                    @include max(1200px) {
                        font-size: 1.6rem;
                    }
                    @include max(575px) {
                        font-size: 1.4rem;
                    }
                }
            }
        }
    }
}

.his-slider-thumb{
    @include max(1200px) {
        width: calc(9/12*100%);
    }
    @include max(700px) {
        width: 100%;
    }
    .thumb-his{
        position: relative;
        z-index: 2;
    }
    .thumb-his-inner{
        position: absolute;
        inset: 0;
        width: 100%;
        height: 100%;
        &::after{
            content: '';
            position: absolute;
            inset: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 45%, rgba(0, 0, 0, 0.80) 75%);
        }
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .swiper-slide{
        &.swiper-slide-active{
            .his-content{
                .tt-card, .desc{
                    opacity: 1;
                    visibility: visible;
                    transform: translateY(0);
                }
            }
        }
    }
    .his-content{
        padding-top: 59.2rem;
        @include max(1024px) {
            padding-top: 39rem;
        }
        @include max(800px) {
            padding-top: 25rem;
        }
        @include max(500px) {
            padding-top: 15rem;
        }
        .inner{
            padding: 0 4rem 4rem 4rem;
            @include max(1200px) {
                padding: 0 2rem 2rem 2rem;
            }
        }
        .tt-card{
            color: #F5F7F7;
            margin-bottom: 1.2rem;
            opacity: 0;
            visibility: hidden;
            transform: translateY(4rem);
            transition: all 0.6s;
            transition-delay: 0.2s;
        }
        .desc{
            color: #F5F7F7;
            font-size: 1.6rem;
            font-weight: 500;
            opacity: 0;
            visibility: hidden;
            transform: translateY(4rem);
            transition: all 0.6s;
            transition-delay: 0.4s;
        }
    }
}

.his-sect{
    .heading-sect{
        justify-content: center;
        width: calc(7/12*100%);
        margin: 0 auto 4.8rem;
        @include max(1200px) {
            margin: 0 auto 2.8rem;
        }
        @include max(700px) {
            width: 100%;
        }
        .desc{
            @include max(1620px) {
                font-size: 1.6rem
            }
            @include max(575px) {
                font-size: 1.4rem
            }
        }
    }
}

.structure-content {
    padding-top: 5rem;
    .structure-content-img{
        @include max(767px) {
            width: 70%;
            margin: 0 auto
        }
        @include max(575px) {
            width: 100%;
        }
        .img{
            @include imgPd(375,666);
            position: relative;
            overflow: hidden;
            &::before{
                content: '';
                position: absolute;
                top: 0;
                left: -100%;
                z-index: 2;
                display: block;
                content: '';
                width: 50%;
                height: 100%;
                background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.3) 100%);
                transform: skewX(-25deg);
            }
            &.active{
                .img{
                    animation: shine 1.6s ease-in forwards;
                }
            }
        }
        img{
            @include imgCover;
        }
    }
    .structure-content-desc{
        @include max(767px) {
            width: 100%;
        }
        .desc{
            font-weight: 500;
        }
    }
}


.page-about{
    .thumb-box-content-sect{
        .content-box-list{
            margin-top: -24rem;
            @include max(992px) {
                margin-top: 3rem;
            }
        }
        // .content-card-box{
        //     background-color: $white;
        //     box-shadow: 0px 4px 35.3px 0px rgba(18, 27, 44, 0.08);
        // }
    }
    .content-box-list-wr{
        align-items: flex-end;
        @include max(992px) {
            align-items: stretch;
        }
    }
}

