.popup{
    position: fixed;
    z-index: 51;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    &.popup-career{
        background-color: $white;
        max-width: 120rem;
        width: 100%;
        max-height: 80rem;
        overflow: auto;
        opacity: 0;
        visibility: hidden;
        clip-path: inset(0 0 100% 0);
        transition: all .4s;
        @include max(1200px) {
            max-width: 75rem;
        }
        @include max(800px) {
            max-width: 90%;
        }
        @include max(575px) {
            max-height: 85vh;
        }
        &.open{
            opacity: 1;
            visibility: visible;
            clip-path: inset(0 0 0 0);
        }
        .popup-career-wr{
            padding: 10rem 20.8rem;
            @include max(1620px) {
                padding: 5rem 14rem;
            }
            @include max(1200px) {
                padding: 5rem 8rem;
            }
            @include max(800px) {
                padding: 5rem;
            }
            @include max(600px) {
                padding: 5rem 2rem 2rem 2rem;
            }
        }
        .ic-close{
            position: absolute;
            top: 3.6rem;
            right: 3.6rem;
            width: 4.8rem;
            height: 4.8rem;
            border-radius: 50%;
            background-color: #F5F7F7;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            transition: all 0.6s ease;
            @include max(1200px) {
                top: 2rem;
                right: 2rem;
            }
            @include max(575px) {
                width: 3.2rem;
                height: 3.2rem;
            }
            &:hover{
                transform: rotate(360deg);
            }
            .img{
                width: 2.4rem;
                height: 2.4rem;
                @include max(575px) {
                    width: 2rem;
                    height: 2rem;
                }
                img{
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
}