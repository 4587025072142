.swiper-pagination {
    position: static;
    bottom: 2.4rem;
    display: flex;
    flex-direction: column;
    width: fit-content !important;
    row-gap: 0.5rem;
    margin-left: 2.5rem;
    @include max(1200px) {
        margin-left: 1.5rem;
    }
    .swiper-pagination-bullet{
        width: 1rem;
        height: 1rem;
        background-color: #EAEAEA;
        opacity: 1;
        border-radius: 50%;
        transition: all .4s ease-out;
        &.swiper-pagination-bullet-active{
            background-color: $color-pri;
        }
    }
    &.second{
        position: static;
        .swiper-pagination-bullet{
            background-color: $color-text;
            &.swiper-pagination-bullet-active{
                background-color: $color-pri;
                transform: rotate(45deg);
            }
        }
        
    }
}

.swiper-slide{
    height: auto;
}


.box-navi{
    width: 100%;
    display: flex;
    justify-content: space-between;
    .btn-navi{
        position: absolute;
        z-index: 2;
        top: 50%;
        transform: translate(0, -50%);
        transition: .4s;
        cursor: pointer;
        .ic{
            display: flex;
            justify-content: center;
            width: 4.8rem;
            height: 4.8rem;
            @include max(1620px) {
                width: 3.8rem;
                height: 3.8rem;
            }
            @include max(1200px) {
                width: 3rem;
                height: 3rem;
            }
        }
        &.prev{
            left: -4rem;
            @include max(1320px) {
                left: 0;
            }
        }
        &.next{
            right: -4rem;
            @include max(1320px) {
                right: 0;
            }
        }
        &.swiper-button-disabled{
            opacity: 0.5;
            pointer-events: none;
        }
    }
    &.second{
    }
}