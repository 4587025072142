.blog-list-sect{
    .blog-lf{
        .card{
            margin-bottom: 3.2rem;
            &:last-child{
                margin-bottom: 0;
            }
        }
        .info{
            margin-bottom: 1.2rem;
        }
        .tt-card{
            font-size: 2.4rem;
            font-weight: 600;
            margin-bottom: 1.2rem;
            @include max(1200px) {
                font-size: 2rem;
            }
            @include max(575px) {
                font-size: 1.6rem;
            }
        }
        .desc-card{
            font-size: 1.6rem;
            @include max(575px) {
                font-size: 1.4rem;
            }
        }
        .thumb-card{
            width: calc(3.7/8*100%);
            padding-top: 0;
            aspect-ratio: 384/288;
            // padding: 0 var(--it-pd);
            margin-bottom: 0;
            overflow: hidden;
            flex-shrink: 0;
            @include max(575px) {
                padding-top: calc(288/384*100%);
                width: auto;
                margin-bottom: 1.2rem;
                flex-shrink: unset;
            }
            &:hover{
                img{
                    transform: translate(0,0) scale(1.1);
                    @include max(575px) {
                        transform: translate(-50%,-50%) scale(1.1);
                    }
                }
            }
            img{
                position: unset;
                transform: translate(0,0);
                @include max(575px) {
                    position: absolute;
                    transform: translate(-50%, -50%);
                }
            }
        }
        .content-card{
            // padding: 0 var(--it-pd);
        }
        .card-inner{
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 3.2rem;
            @include max(1200px) {
                gap: 1.6rem;
            }
            @include max(575px) {
                flex-direction: column;
            }
        }
    }
    
}
.blog-rt{
    .blog-rt-wr{
        @include max(767px) {
            @include row(0.8);
        }
    }
    .card{
        margin-bottom: 3.2rem;
        @include max(1200px) {
            margin-bottom: 1.6rem;
        }
        @include max(767px) {
            width: 50%;
            padding: 0 var(--it-pd);
        }
        @include max(575px) {
            width: 100%;
        }
        &:last-child{
            margin-bottom: 0;
        }
    }
    .info{
        margin-bottom: 0.6rem;
    }
    .tt-card{
        font-size: 1.8rem;
        margin-bottom: 1.2rem;
        @include max(575px) {
            font-size: 1.6rem;
        }
    }
    .desc-card{
        font-size: 1.6rem;
        @include max(575px) {
            font-size: 1.4rem;
        }
    }
    .thumb-card{
        display: none;
    }
    .card-inner{
        padding: 1.6rem;
        transition: all .3s;
        &:hover{
            background-color: #F5F7F7;
        }
        @include max(767px) {
            background-color: #F5F7F7;
        }
    }
}

.blog-filter-lf-wr{
    .result-search{
        font-family: var(--font-sec);
        display: block;
        margin-top: 0.6rem;
        color: #3A4040;
        font-size: 1.2rem;
    }
}

.blog-filter-rt-wr{
    overflow: auto;
    &::-webkit-scrollbar {
        width: 100%;
        height: 0.5rem;
    }
    &::-webkit-scrollbar-track {
        background: #f1f1f1;
    }    
    &::-webkit-scrollbar-thumb {
        background: $color-pri;
    }    
    &::-webkit-scrollbar-thumb:hover {
        background: $color-pri;
    }
    .tab-blog{
        width: max-content;
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        gap: 1.6rem;
        padding-bottom: 2rem;
    }
    .tab-btn-blog{
        border: 1px solid #E1E8E8;
        cursor: pointer;
        &:first-child{
            .txt{
                &::before{
                    content: '';
                    display: flex;
                    align-items: center;
                    background-image: url(/template/assets/images/all.svg);
                    background-repeat: no-repeat;
                    background-size: 1.6rem 1.6rem;
                    width: 1.6rem;
                    height: 1.6rem;
                    margin-right: 0.8rem;
                    filter: brightness(0) saturate(100%) invert(13%) sepia(12%) saturate(6482%) hue-rotate(181deg) brightness(101%) contrast(108%);
                }
            }
        }
        .txt{
            color: #606969;
            font-size: 1.6rem;
            display: flex;
            align-items: center;
            padding: 1rem 3.2rem;
            @include max(1200px) {
                padding: 1rem 2rem;
                font-size: 1.4rem;
            }
        }
        &.active{
            background-color: $color-pri;
            .txt{
                color: $white;
                &::before{
                    filter: none;
                }
            }
        }
    }
}


.organize-box-content{
    .box-content{
        &:nth-child(even){
            .box-content-wr{
                flex-direction: row-reverse;
            }
        }
    }
}