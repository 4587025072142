.btn{
    position: relative;
    z-index: 2;
    font-family: var(--font-pri);
    width: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 5.6rem;
    transition: all 0.4s ease;
    // border-radius: 1.6rem;
    border: 0.1rem solid transparent;
    overflow: hidden;
    cursor: pointer;
    @media (max-width:1200px){
        min-height: 4rem;
    }
    .txt{
        color: $white;
        font-size: 1.6rem;
        line-height: 150%;
        font-weight: 600;
        transition: 0.4s ease-in-out;
        @media (max-width:1200px){
            font-size: 1.4rem;
        }
    }
    &::after{
        content: '';
        position: absolute;
        z-index: -1;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        transform: translate(0%,-105%);
        transition: all 0.4s ease;
        overflow: hidden;
    }
    &:hover{
        &::after{
            transform: translate(0,0);
        }
    }
    &.icon{
        .ic{
            display: flex;
        }
    }
    .ic{
        display: none;
        width: 1.6rem;
        height: 1.6rem;
        flex-shrink: 0;
        margin-left: 1rem;
        @include max(1200px) {
            margin-left: 0.5rem;
        }
        img{
            width: 100%;
            height: 100%;
            transition: all .2s;
        }
    }
}

.btn.btn-pri{
    background-color: $color-second;
    padding: 1.6rem 4rem;
    border-color: $color-second;
    @include max(575px) {
        padding: 1.2rem 2.6rem;
    }
    &::after{
        background-color: $white;
    }
    &:hover{
        .txt{
            color: $color-second;
        }
        .ic{
            img{
                filter: brightness(0) saturate(100%) invert(19%) sepia(74%) saturate(4507%) hue-rotate(348deg) brightness(122%) contrast(86%);            }
        }
    }
}

.btn.btn-sec{
    background-color: $color-pri;
    padding: 1.6rem 4rem;
    border-color: $color-pri;
    @include max(575px) {
        padding: 1.2rem 2.6rem;
    }
    &::after{
        background-color: $white;
    }
    &:hover{
        .txt{
            color: $color-pri;
        }
        .ic{
            img{
                filter: brightness(0) saturate(100%) invert(13%) sepia(19%) saturate(4275%) hue-rotate(182deg) brightness(94%) contrast(106%);        
            }
        }
    }
}

.btn.btn-third{
    background-color: $color-pri;
    padding: 1.6rem 4rem;
    border-color: $color-pri;
    @include max(575px) {
        padding: 1.2rem 2.6rem;
    }
    &::after{
        background-color: $color-second;
    }
    &:hover{
        border-color: $color-second;
        .txt{
            color: $white;
        }
        .ic{
            img{
                filter: brightness(0) saturate(100%) invert(19%) sepia(74%) saturate(4507%) hue-rotate(348deg) brightness(122%) contrast(86%);            }
        }
    }
}