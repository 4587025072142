.ft{
    background-color: $color-pri;
    padding-top: var(--ss-pd);
    overflow: hidden;
    .container{
        max-width: var(--container-ft);
    }
    .ft-up{
        padding-bottom: 2.4rem;
        margin-bottom: 2.4rem;
        border-bottom: 1px solid  rgba(161, 166, 171, 0.30);
    }
    .ft-bot{
        padding: 1.4rem 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        row-gap: 1rem;
        @include max(500px) {
            justify-content: center;
        }
        .ft-bot-author{
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
            gap: 1.1rem;
            font-size: 1.4rem;
            color: #F5F6F7;
            line-height: 2.2rem;
            img{
                height: 1.3rem;
            }
        }
        .ft-bot-social{
            .social-link{
                background-color: $color-pri;
            }
        }
    }
}

.ft-wr{
    justify-content: space-between;
}

.ft-item{
    font-family: var(--font-sec);
    padding: 0 var(--it-pd);
    .label-item{
        color: $gray;
        font-weight: 700;
    }
    &:first-child{
        width: calc(3.6/12*100%);
        @include max(850px) {
            width: 50%;
        }
        @include max(575px) {
            width: 100%;
        }
    }
    &:nth-child(2){
        width: calc(2.5/12*100%);
        @include max(1200px) {
            width: calc(2.8/12*100%);
        }
        @include max(850px) {
            width: 50%;
        }
        @include max(380px) {
            width: 100%;
        }
    }
    &:nth-child(3){
        width: calc(2.5/12*100%);
        @include max(1200px) {
            width: calc(2.8/12*100%);
        }
        @include max(850px) {
            width: 50%;
        }
        @include max(380px) {
            width: 100%;
        }
    }
    &:last-child{
        width: calc(2.5/12*100%);
        @include max(1200px) {
            width: calc(2.8/12*100%);
        }
        @include max(850px) {
            width: 50%;
        }
        @include max(380px) {
            width: 100%;
        }
        .menu-list{
            .menu-item{
                margin-bottom: 1rem;
            }
        }
    }
    .custom-logo-link{
        display: flex;
        width: var(--w-logo-ft);
        @include max(575px) {
            margin: 0 auto;
        }
        img{
            width: 100%;
            height: 100%;
        }
    }
    .desc {
        color: $gray;
        margin-top: 2.4rem;
        @include max(1200px) {
            font-size: 1.6rem;
        }
        @include max(575px) {
            text-align: center;
        }
    }
    .tt-ft{
        color: $white;
        font-size: 2.4rem;
        font-weight: 700;
        line-height: 150%;
        margin-bottom: 1.6rem;
        @include max(1200px) {
            font-size: 2rem;
        }
    }
    .menu-list{
        .menu-item{
            margin-bottom: 1.6rem;
            &.current-menu-item{
                .menu-link{
                    color: $color-second;
                    font-weight: 600;
                }
            }
            @include max(1200px) {
                font-size: 1.6rem;
            }
            &:last-child{
                margin-bottom: 0;
            }
            .menu-link{
                color: $gray;
                transition: .4s;
            }
            &:hover{
                .menu-link{
                    color: $color-second;
                }
            }
        }
    }
}