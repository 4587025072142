
.majors-filter{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    row-gap: 1.6rem;
    .box-search{
        width: calc(4/12*100% - 1.6rem);
        @include max(750px) {
            width: 50%;
        }
        @include max(390px) {
            width: 100%;
        }
    }
    >.txt{
        font-size: 1.4rem;
    }
}
.majors-list{
    .card{
        width: calc(4/12*100%);
        padding: 0 var(--it-pd);
        @include max(750px) {
            width: 50%;
        }
        @include max(390px) {
            width: 100%;
        }
        .thumb-card{
            padding-top: calc(300/384*100%);
        }
        .tt-card{
            font-size: 2.4rem;
            font-weight: 600;
            @include max(1200px) {
                font-size: 2rem;
            }
            @include max(575px){
                font-size: 1.6rem;
            }
        }
    }
}