.bar-fixed {
    position: fixed;
    z-index: 11;
    bottom: 2.5rem;
    right: 2rem;
    transform: translate(100%, 0);
    opacity: 0;
    visibility: hidden;
    transition: all .4s linear;
    @media (max-width:575px){
        right: 1.5rem;
        bottom: 6.5rem;
    }
    .bar-fixed-item {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 4.8rem;
        height: 4.8rem;
        margin-bottom: 2rem;
        cursor: pointer;
        &:last-child{
            margin-bottom: 0;
        }
        &:not(:last-child){
            &::before, &::after{
                content: '';
                display: block;
                width: 100%;
                height: 100%;
                top: 0rem;
                left: 0rem;
                position: absolute;
                z-index: -1;
                border-radius: 50%;
                background-color: $color-second;
                animation: scaleAni 1.8s linear infinite;
                opacity: 0;
            }
            &::after{
                animation-delay: 0.5s;
            }
            .ic{
            animation: ring-ring 2s infinite;
        }
        }
        @include max(1200px) {
            width: 3.8rem;
            height: 3.8rem;
        }
        .ic{
            display: flex;
            align-items: center;
            justify-content: center;
            width: 2rem;
            height: 2rem;
            // @include max(1200px) {
            //     width: 2rem;
            //     height: 2rem;
            // }
            i{
                font-size: 1.6rem;
                color: $white;
                @include max(1200px) {
                    font-size: 1.4rem;
                }
            }
            img{
                width: 100%;
                height: 100%;
                filter: brightness(0) saturate(100%) invert(100%) sepia(90%) saturate(2%) hue-rotate(150deg) brightness(109%) contrast(101%);
            }
        }
    }
    .back-to-top{
        width: 4.7rem;
        height: 4.7rem;
        border: 0.1rem solid $color-second;
        background-color: $white;
        border-radius: 50%;
        transition: all .3s linear;
        @include max(1200px) {
            width: 3.7rem;
            height: 3.7rem;
        }
        .ic{
            i{
                color: $color-second;
                transition: all .3s linear;
            }
        }
        &:hover{
            background-color: $color-second;
            .ic{
                i{
                    color: $white;
                }
            }
        }
    }
    .phone, .mess, .zalo{
        .ic{
            animation: ring-ring 2s infinite;
        }
    }
    &.active{
        transform: translate(0%, 0);
        opacity: 1;
        visibility: visible;
    }
}