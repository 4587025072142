.card{
    .card-inner{
        @include flexCol;
        height: 100%;
    }
    .thumb-card{
        @include imgPd(164, 280);
        margin-bottom: 1.2rem;
        &:hover{
            img{
                transform: translate(-50%, -50%) scale(1.1);
            }
        }
        img{
            @include imgCover;
            transition: all .3s;
        }
    }
    .content-card{
        @include flexCol;
        height: 100%;
        .info-wr{
            .author{
                font-size: 1.2rem;
            }
            .date{
                font-size: 1.2rem;
            }
        }
    }
    .link{
        // margin-top: 2.5rem;
        // @include max(1200px) {
        //     margin-top: 1.5rem;
        // }
        margin-top: auto;
        padding-top: 1rem;
    }
    .tt-card{
        &:hover{
            color: $color-pri;
        }
    }
    .desc-card{
        color: #606069;
        @include lc(2);
    }
    .link{
        color: $color-pri;
        display: flex;
        align-items: center;
        font-size: 1.4rem;
        font-weight: 700;
        .ic{
            @include icon(1.6, 1.6);
            margin-left: 1.2rem;
            img{
                width: 100%;
                height: 100%;
            }
        }
    }
}