:root {
    --font-pri: 'Raleway', serif;
    --font-sec: 'Montserrat', serif;
    --font-third: 'Barlow', serif;
    --w-logo: 9rem;
    --w-logo-ft: 30.2rem;
    --hd-up: 13.4rem;
    --hd-bot: 4.8rem;
    --size-hd: calc(13.4rem + 4.8rem);
    --ss-pd: 10rem;
    --ss-pd-t: 10rem;
    --ss-pd-b: 10rem;
    --it-pd: 1.6rem;
    --container-hd: 152.8rem;
    --container-ft:: 142.6rem;
    @media (max-width: 1520px) {
        --container-hd: 135.8rem;
    }
    @media (max-width:1200px) {
        --ss-pd::  5rem;
        --w-logo-ft: 22.2rem;
        --w-logo: 7rem;
        --it-pd:: 0.8rem;
        --hd-up: 8.5rem;
        --size-hd: 8.5rem;
    }

    @media (max-width:575px) {
        --hd-up: 6.5rem;
        --size-hd: 6.5rem;
        --w-logo: 5.8rem;
    }
}

// color
$color-pri: #002244;
$color-second: #B3995D;
$color-third: #101212;
$color-text: #3A3A40;
$black: #000000;
$white: #ffffff;
$white-pri: #F5F7F7;
$white-sec: #EDF2F2;
$gray: #D0D5D9;
