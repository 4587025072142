::-webkit-scrollbar {
    width: 0.5rem;
}

/* Track */

::-webkit-scrollbar-track {
    background: #f1f1f1;
}


/* Handle */

::-webkit-scrollbar-thumb {
    background: $color-pri;
}


/* Handle on hover */

::-webkit-scrollbar-thumb:hover {
    background: $color-pri;
}



