.paginations, .load-more-pagintion{
    margin-top: 4rem;
    @include max(1200px) {
        margin-top: 2rem;
    }}
.page-numbers{
    display: flex;
    justify-content: center;
    align-items: center;
    // margin-top: 4rem;
    // @include max(1200px) {
    //     margin-top: 2rem;
    // }
    @include max(700px) {
        justify-content: center;
    }
    li{
        margin-right: 1.2rem;
        @include max(575px) {
            margin-right: 0.6rem;
        }
        &:last-child{
            margin-right: 0;
        }
        >.prev.page-numbers{
            font-size: 1.6rem;
            @include max(575px) {
                font-size: 1.4rem;
            }
            &:hover{
                background-color: transparent;
            }
            i{
                margin-right: 0.4rem;
            }
        }
        >.next.page-numbers{
            font-size: 1.6rem;
            @include max(575px) {
                font-size: 1.4rem;
            }
            &:hover{
                background-color: transparent;
            }
            i{
                margin-left: 0.4rem;
            }
        }
        >.page-numbers.current{
            display: flex;
            padding: 0.5rem;
            background: #F5F7F7;
            color: $color-third;
            transform: 0.4s;
        }
        >.page-numbers{
            display: flex;
            justify-content: center;
            color: $color-third;
            height: 4rem;
            min-width: 4rem;
            font-size: 1.4rem;
            font-weight: 500;
            transition: 0.4s;
            font-family: var(--font-sec);
            @include max(575px) {
                font-size: 1.2rem;
                min-width: 3rem;
                height: 3rem;
            }
        }
        &:hover{
            >.page-numbers{
                background: #F5F7F7;
                color: $color-third;
            }
        }
    }
}