.spring{
    animation: spring 3s infinite linear both;
}

.dance{
    animation:  dance-2 2s linear infinite;
}

.bounce-in-right {
    @include min(575px) {
        transform: translate3d(6000px,0,0) scaleX(3);
    }
    &.active{
        animation: bounce-in-right 2s forwards;
    }
}

.add-active-js{
    &.active{
        .bounce-in-right{
            animation: bounce-in-right 2s forwards;
            @include max(575px) {
                animation: none;
            }
        }
        .bounce-in-left{
            animation: bounce-in-left 2s forwards;
            @include max(575px) {
                animation: none;
            }
        }
    }
}

.shakeJump{
    animation: shakeJump 2s ease infinite;
}

.walking{
    animation: walking 4s linear infinite both;
}

.bounce-in-left {
    @include min(575px) {
        transform: translate3d(-3000px,0,0) scaleX(3);
    }
    &.active{
        animation: bounce-in-left 2s forwards;
        @include max(575px) {
            animation: none;
        }
    }
}

.showUp{
    animation: showUp 2s linear forwards;
}

.spring-rotate{
    animation: springScroll 3s linear infinite;
}

.rotateMove{
    animation: rotateMove 2s linear forwards;
}

.bounce{
    animation: bounce 1.5s infinite ease-in-out;
    @media (max-width: 1720px) {
        animation: bounce2 1.5s infinite ease-in-out;
    }
    @media (max-width: 1200px) {
        animation: bounce3 1.5s infinite ease-in-out;
    }
}