.select2-container{
    // width: 100% !important;
    .select2-selection--single{
        font-family: var(--font-sec);
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: #F5F7F7 !important;
        border: none !important;
        border-radius: 0 !important;
        padding: 0.8rem 0.8rem 0.8rem 3.2rem;
        @include max(1200px) {
            padding: 0.8rem 0.8rem 0.8rem 1.6rem;
        }
    }
    .select2-selection__rendered{
        font-size: 1.4rem !important;
        color: $color-text !important;
        font-weight: 400 !important;
        padding: 0 !important;
    }
    .select2-selection__arrow{
        background-image: url(/template/assets/images/arr-down.svg);
        background-repeat: no-repeat;
        background-size: 2rem;
        transition: .4s;
        position: static !important;
        width: 2rem !important;
        height: 2rem !important;
        display: block !important;
        >b{
            display: none;
        }
    }
    .select2-dropdown {
        z-index: 10;
        box-shadow: 0 1rem 3rem 0rem rgba(0,0,0,0.2);
    }
}
.select2-container--open{
    .select2-selection__arrow{
        transform: rotate(180deg);
    }
}

.select2-results__option--selectable {
    transition: .3s linear;
}

.select2-results__options{
    font-family: var(--font-sec);
    font-size: 1.4rem;
}

.select2-container--default .select2-results__option--highlighted.select2-results__option--selectable {
    background-color: $color-pri;
}

.select2-container {
    .select2-search {
        display: none;
    }
    .select2-dropdown{
        border: none;
    }
}

.input{
    .select2-container{
        width: 100% !important;
        height: 100% !important;
    }
}