.breadcrumbs-list{
    flex-wrap: wrap;
    .breadcrumbs-item{
        display: flex;
        align-items: center;
        &:not(:last-child){
            &::after{
                content: '/';
                display: block;
                width: 1.2rem;
                height: 100%;
                border-radius: 50%;
                // background-color: $white;
                margin: 0 0.4rem;
            }
        }
        // &:not(:first-child) {
        //     color: $color-third;
        //     font-weight: 600;
        // }
        &:last-child{
            color: $color-third;
            font-weight: 600;
        }
        .breadcrumbs-link{
            @include lc(2);
        }
    }
}