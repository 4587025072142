.banner-sect{
    .container{
        max-width: calc(100vw - (100vw - 123rem)/2);
        margin:0 0 0 auto;
        padding: 0;
        @include max(1200px) {
            max-width: 100%;
        }
    }
}
.banner-wr{
    position: relative;
    z-index: 2;
    padding-left: 1.5rem;
    &::after{
        content: '';
        position: absolute;
        z-index: -1;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background: linear-gradient(-90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.1) 0%, #fff 100%);
        @include min(1200px) {
            display: none;
        }
    }
    .bg{
        position: absolute;
        z-index: -1;
        top: 0;
        right: 0;
        width: 100%;
        max-width: 68%;
        height: 100%;
        @include max(1200px) {
            max-width: 100%;
        }
        &.bg-repon{
            display: none;
        }
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
            // object-position: right;
            // @include max(1200px) {
            //     object-fit: center;
            // }
        }
    }
    .banner-content{
        position: relative;
        // width: calc(3.5/12*100%);
        width: calc(4/12*100%);
        background: rgba(255 , 255, 255, 1);
        padding: 13.8rem 0;
        @include max(1200px) {
            width: auto;
            background: transparent;
        }
        @include max(575px) {
            padding: 10.8rem 0;
        }
        &::before, &::after{
            content: '';
            position: absolute;
            z-index: -1;
            top: 0;
            height: 100%;
            background: linear-gradient(90deg, #FFF 0%, rgba(255, 255, 255, 0.00) 100%);
            @include max(1200px) {
                display: none;
            }
        }
        &::before{
            width: 50%;
            // right: -50%;
            right: -25%;
        }
        &::after{
            width: 100%;
            right: -100%;
        }
        .tt-sect{
            margin-top: 1.6rem;
        }
    }
}

.banner-home{
    .banner-home-wr{
        position: relative;
    }
    .bg{
        position: absolute;
        z-index: -1;
        inset: 0;
        width: 100%;
        height: 100%;
        // &::after{
        //     content: '';
        //     position: absolute;
        //     inset: 0;
        //     width: 100%;
        //     height: 100%;
        //     background: rgba(0, 34, 68, 0.60);;
        // }
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .banner-home-content{
        padding: 23.2rem 0;
        @include max(1620px) {
            padding: 15rem 0;
        }
        @include max(767px) {
            padding: 10rem 0;
        }
    }
    .banner-home-content-box{
        width: calc(6/12*100%);
        @include max(1200px) {
            width: calc(12/12*100%);
        }
        // .stt{
        //     display: block;
        //     color: #F5F6F7;
        //     font-size: 1.6rem;
        //     line-height: 150%;
        //     font-weight: 600;
        //     text-transform: uppercase;
        //     opacity: 0;
        //     visibility: hidden;
        //     transform: translateY(5rem);
        //     transition: all .3s linear;
        //     transition-delay: 0.2s;
        //     @include max(1200px) {
        //         font-size: 1.4rem;
        //     }
        //     @include max(575px) {
        //         font-size: 1.2rem;
        //     }
        // }
        .stt{
            display: block;
            width: calc(2.8/6*100%);
            transition: all 1s linear;
            clip-path: polygon(0% 0%, 100% 0%, 100% 0%, 100% 100%, 100% 100%, 100% 0%, 0% 0%, 0% 100%);
            transform: rotate(1deg);
            transition-delay: 0.8s;
        }
        .tt-sect{
            margin-bottom: 1.6rem;
            opacity: 0;
            visibility: hidden;
            transform: translateY(5rem);
            transition: all .3s linear;
            transition-delay: 0.4s;
        }
        .desc{
            color: #EDEFF2;
            font-size: 1.6rem;
            margin-bottom: 2.4rem;
            opacity: 0;
            visibility: hidden;
            transform: translateY(5rem);
            transition: all .3s linear;
            transition-delay: 0.6s;
            @include max(1200px) {
                font-size: 1.4rem;
            }
        }
        .btn{
            opacity: 0;
            visibility: hidden;
            transform: translateY(5rem);
            transition: all .3s linear;
            transition-delay: 0.8s;
        }
    }
    .banner-thumb-slider{
        position: absolute;
        z-index: 1;
        right: 0;
        bottom: -8rem;
        max-width: calc(100vw - (100vw - (123rem - calc(5/12*100%)))/2);
        width: 100%;
        margin-left: auto;
        overflow: hidden;
        @include max(1620px) {
            max-width: calc(100vw - (100vw - (123rem - calc(7/12*100%)))/2);
            bottom: -7rem;
        }
        @include max(1200px) {
            max-width: calc(100vw - (100vw - (123rem - calc(10/12*100%)))/2);
        }
        @include max(1024px) {
            max-width: calc(100vw - (100vw - (123rem - calc(12/12*100%)))/2);
            bottom: -8rem;
        }
        @include max(767px) {
            position: unset;
            max-width: 100%;
            background: rgba(0, 34, 68,1);
            padding: 2rem 0;
            // padding: 0 1.5rem;
        }
    }
    .banner-thumb-slider-wr{
        @include max(767px) {
            overflow: hidden;
        }
        .swiper{
            margin: 0 calc(-1 * var(--it-pd));
        }
        .swiper-slide{
            width: calc(4.5/12*100%);
            padding: 0 var(--it-pd);
            height: auto;
            @include max(575px) {
                width: 50%;
            }
            @include max(390px) {
                width: 85%;
            }
            .thumb{
                @include imgPd(288, 384);
                img{
                    @include imgCover;
                }
            }
        }
        .box-navi{
            position: unset;
            width: calc(4/12*100%) !important;
            justify-content: center;
            padding-bottom: 1.6rem;
            gap: 1.6rem;
            @include max(767px) {
                margin: 0 auto;
            }
            .btn-navi{
                position: unset;
                width: 4.8rem;
                height: 4.8rem;
                border-radius: 50%;
                border: 1px solid #F5F6F7;
                display: flex;
                justify-content: center;
                align-items: center;
                @include max(1200px) {
                    width: 3.7rem;
                    height: 3.7rem;
                }
                .ic{
                    width: 2.8rem;
                    height: 2.8rem;
                    @include max(1200px) {
                        width: 2.2rem;
                        height: 2.2rem;
                    }
                }
                &.prev{
                    transform: translate(0,0);
                    left: 0;
                    top: 0;
                }
                &.next{
                    transform: translate(0,0);
                    right: 0;
                    top: 0;
                }
            }
        }
    }
    .banner-bg-slider{
        .swiper-slide{
            &.swiper-slide-active{
                .banner-home-content-box{
                    .tt-sect, .desc, .btn{
                        opacity: 1;
                        visibility: visible;
                        transform: translateY(0);
                    }
                    .stt{
                        clip-path: polygon(0% 0%, 100% 0%, 100% 0%, 100% 100%, 100% 100%, 100% 0%, 100% 100%, 0% 100%);
                    }
                }
            }
        }
    }
}