.mission-main-wr{
    .box-content{
        margin-bottom: 4.8rem;
        @include max(1200px) {
            margin-bottom: 2.8rem;
        }
        &:last-child{
            margin-bottom: 0;
        }
        &:nth-child(odd){
            .box-content-wr{
                flex-direction: row-reverse;
            }
        }
    }
}

.mission-main {
    overflow: hidden;
}

.person-work-sect{
    .bg{
        &::after{
            background: linear-gradient(0deg, rgba(0, 34, 68, 0.95) 0%, rgba(0, 34, 68, 0.95) 100%);
        }
    }
    .slider-lg-block{
        .content-inner{
            .stt{
                color: #EDF2F2;
                font-size: 1.1rem;
            }
            .tt-card{
                margin-bottom: 1.6rem;
            }
            .desc{
                // max-height: 10rem;
                // overflow: auto;
                // @include max(400px) {
                //     max-height: 6rem;
                //     margin-top: 1.2rem;
                // }
                .mona-content{
                    font-family: var(--font-pri);
                    font-size: 1.1rem;
                    ul{
                        font-size: 1.1rem;
                        color: #EDF2F2;
                        font-weight: 400;
                    }
                }
            }
        }
    }
}