@keyframes ring-ring {
    0%{
        transform: rotate(0deg) scale(1) skew(1deg);
    }
    10%{
        transform: rotate(-25deg) scale(1) skew(1deg);
    }
    20%{
        transform: rotate(25deg) scale(1) skew(1deg);
    }
    30%{
        transform: rotate(-25deg) scale(1) skew(1deg);
    }
    40%{
        transform: rotate(25deg) scale(1) skew(1deg);
    }
    50%{
        transform: rotate(0deg) scale(1) skew(1deg);
    }
    100%{
        transform: rotate(0deg) scale(1) skew(1deg);
    }
}

@keyframes shine {
    100%{
        left: 125%;
    }
}



@keyframes zoomTab {
    0%{
        display: none;
        opacity: 0;
        transform: scale(.95);
    }
    100%{
        display: block;
        opacity: 1;
        transform: scale(1);
    }
}


@keyframes autoScroll {
    0%{
        transform: translateY(0);
    }
    100%{
        transform: translateY(-100%);
    }
}

@keyframes runDown {
    0%{
        opacity: 0;
        transform: translate(0, -2rem) scale(1);
    }
    50%{
        opacity: 1;
    }
    100%{
        opacity: 0;
        transform: translate(0, 2rem) scale(0.8);
    }
}

@keyframes arrowRight {
    0%{
        opacity: 0;
        transform: translate(-1rem, 0);
    }
    50%{
        opacity: 1;
    }
    100%{
        opacity: 0;
        transform: translate(1rem, 0);
    }
}


@keyframes scaleOpa {
    0%{
        opacity: 0;
        transform: scale(1);
    }
    50%{
        opacity: 1;
    }
    100%{
        opacity: 0;
        transform: scale(0.8);
    }
}

@keyframes lightNeon {
    // 0%, 100% {
    //     opacity: 1;
    //     }
    // 25%, 75% {
    //     opacity: 0;
    // }
    0%    { opacity: 1; }
    3%    { opacity: 0.4; }
    6%    { opacity: 1; }
    7%    { opacity: 0.4; }
    8%    { opacity: 1; }
    9%    { opacity: 0.4; }
    10%   { opacity: 1; }       
    89%   { opacity: 1; }
    90%   { opacity: 0.4; }
    100%  { opacity: 0.4; }
}


@keyframes dashRun {
    100% {
        background-position: 100% 0, 0 100%, 0 0, 100% 100% ;
    }
}

@keyframes aniSlideX {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(calc(-100% + var(--width)));
    }
}

@keyframes upDown {
    0%{
        transform: translateY(0);
    }
    50%{
        transform: translateY(2%);
    }
    100%{
        transform: translateY(0);
    }
}

@keyframes opacity {
    0%{
        opacity: 0.2;
    }
    50%{
        opacity: 1;
    }
    100%{
        opacity: 0.2;
    }
}

@keyframes scale {
    0%{
        transform: scale(0.8);
        opacity: 0;
    }
    50%{
        transform: scale(1);
        opacity: 1;
    }
    100%{
        transform: scale(1.2);
        opacity: 0;
    }
}

@keyframes fadeUp {
    0%{
        transform: translate(0,20%);
        opacity: 0;
        visibility: hidden;
    }
    100%{
        transform: translate(0,0);
        opacity: 1;
        visibility: visible;
    }
}

@keyframes rotate {
    0%{
        transform: rotate(0) scale(1.1);
    }
    100%{
        transform: rotate(180deg) scale(1);
    }
}

@keyframes rotateUpDown {
    0% {
        transform: translateY(0) scale(1);
        opacity: 0.2;
    }
    50% {
        transform: translateY(-15%) scale(1.1);
        opacity: 1;
    }
    100% {
        transform: translateY(0) scale(1);
        opacity: 0.2;
    }
}

@keyframes opa {
    0%{
        opacity: 0.2;
    }
    50%{
        opacity: 1;
    }
    100%{
        opacity: 0.2;
    }
}

@keyframes shooting {
    0% {
        transform: translate(-5px, -3px) rotate(-4deg);
    }
    100% {
        transform: translate(5px, 0) rotate(0deg);
    }
}

@keyframes shooting2 {
    0% {
        transform: translate(10px, -3px) rotate(5deg);
    }
    100% {
        transform: translate(-10px, 0) rotate(0deg);
    }
}

@keyframes bulletMove {
    0% { transform: translateX(0); }
    50% { transform: translateX(200px) ; }
    100% { transform: translateX(400px); }
}

@keyframes scaleAni {
    0%{
        transform: scale(0.5);
        opacity: 0;
    }
    50%{
        opacity: 1;
    }
    100%{
        transform: scale(1.2);
        opacity: 0;
    }
}

@keyframes sparkle {
    0% { transform: scale(0);
    }
    25% { opacity: 1; 
    }
    100% { opacity: 0; 
    }
}

@keyframes showUp {
    0%{
        opacity: 0;
        visibility: hidden;
        transform: translateY(7rem);
    }
    100%{
        opacity: 1;
        visibility: visible;
        transform: translateY(0);
    }
}

@keyframes shakeJump {
    0% { transform: translate(1px, 1px) rotate(0deg); }
    10% { transform: translate(-1px, -2px) rotate(-1deg); }
    20% { transform: translate(-3px, 0px) rotate(1deg); }
    30% { transform: translate(3px, 2px) rotate(0deg); }
    40% { transform: translate(1px, -1px) rotate(1deg); }
    50% { transform: translate(-1px, 2px) rotate(-1deg); }
    60% { transform: translate(-3px, 1px) rotate(0deg); }
    70% { transform: translate(3px, 1px) rotate(-1deg); }
    80% { transform: translate(-1px, -1px) rotate(1deg); }
    90% { transform: translate(0, -2rem) rotate(0deg); }
    100% { transform: translate(0, 0) rotate(0deg); }
}

@keyframes dance {
    0%{
        bottom: 0;
        transform: translate(-50%, -50%) rotate(0);
    }
    20%{
        bottom: 0;
        transform: translate(-50%, -50%) rotate(5deg);
    }
    40%{
        bottom: 0;
        transform: translate(-50%, -50%) rotate(-5deg);
    }
    60%{
        bottom: 0;
        transform: translate(-50%, -50%) rotate(5deg);
    }
    80%{
        bottom: 4rem;
        transform: translate(-50%, -50%) rotate(-5deg);
    }
    100%{
        bottom: 0;
        transform: translate(-50%, -50%) rotate(0);
    }
}


@keyframes spring {
    0%{
        transform: scale3d(1,1,1);
    }
    30%{
        transform: scale3d(1, 0.95, 1);
    }
    40%{
        transform: scale3d(1, 1.05, 1);
    }
    50%{
        transform: scale3d(1, 0.95, 1);
    }
    65%{
        transform: scale3d(1, 1.05, 1);
    }
    75%{
        transform: scale3d(1, 0.95, 1);
    }
    100%{
        transform: scale3d(1, 1, 1);
    }
}

@keyframes dance-2 {
    0%{
        transform: translate(0, 0) rotate(0);
    }
    20%{
        transform: translate(0, 0) rotate(5deg);
    }
    40%{
        transform: translate(0, 0) rotate(-5deg);
    }
    60%{
        transform: translate(0, 0) rotate(5deg);
    }
    80%{
        transform: translate(0, 3rem) rotate(-5deg);
    }
    100%{
        transform: translate(0, 0) rotate(0);
    }
}

@keyframes walking {
    0%{
        transform: rotate(0) translate(0,0);
    }
    10%{
        transform: rotate(10deg) translate(2rem, 0);
    }
    20%{
        transform: rotate(-10deg) translate(4rem, 0);
    }
    30%{
        transform: rotate(10deg) translate(6rem, 0);
    }
    40%{
        transform: rotate(-10deg) translate(8rem, 0);
    }
    50%{
        transform: rotate(10deg) translate(10rem, 0);
    }
    60%{
        transform: rotate(-10deg) translate(8rem, 0);
    }
    70%{
        transform: rotate(10deg) translate(6rem, 0);
    }
    80%{
        transform: rotate(-10deg) translate(4rem, 0);
    }
    90%{
        transform: rotate(10deg) translate(2rem, 0);
    }
    100%{
        transform: rotate(0) translate(0, 0);
    }
}


@keyframes wave {
    0% {
        margin-left: 0;
    }
    100% {
        margin-left: -1600px;
    }
}

@keyframes swell {
    0%, 100% {
        transform: translate3d(0,-25px,0);
    }
    50% {
        transform: translate3d(0,5px,0);
    }
}

@keyframes bounce-in-right {
    0%, 60%, 75%, 90%, 100% {
        -webkit-animation-timing-function: cubic-bezier(.215,.61,.355,1);
        animation-timing-function: cubic-bezier(.215,.61,.355,1);
    }

    0% {
        opacity: 0;
        -webkit-transform: translate3d(3000px, 0, 0) scaleX(3);
        transform: translate3d(3000px, 0, 0) scaleX(3);
    }
    60% {
        opacity: 1;
        -webkit-transform: translate3d(-25px, 0, 0) scaleX(1);
        transform: translate3d(-25px, 0, 0) scaleX(1);
    }
    75% {
        -webkit-transform: translate3d(10px, 0, 0) scaleX(.98);
        transform: translate3d(10px, 0, 0) scaleX(.98);
    }
    90% {
        -webkit-transform: translate3d(-5px, 0, 0) scaleX(.995);
        transform: translate3d(-5px, 0, 0) scaleX(.995);
    }
    100% {
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
    }
}

@keyframes bounce-in-left {
    0%, 60%, 75%, 90%, 100% {
        -webkit-animation-timing-function: cubic-bezier(.215,.61,.355,1);
        animation-timing-function: cubic-bezier(.215,.61,.355,1);
    }

    0% {
        opacity: 0;
        -webkit-transform: translate3d(-3000px, 0, 0) scaleX(3);
        transform: translate3d(-3000px, 0, 0) scaleX(3);
    }
    60% {
        opacity: 1;
        -webkit-transform: translate3d(25px, 0, 0) scaleX(1);
        transform: translate3d(25px, 0, 0) scaleX(1);
    }
    75% {
        -webkit-transform: translate3d(-10px, 0, 0) scaleX(.98);
        transform: translate3d(-10px, 0, 0) scaleX(.98);
    }
    90% {
        -webkit-transform: translate3d(5px, 0, 0) scaleX(.995);
        transform: translate3d(5px, 0, 0) scaleX(.995);
    }
    100% {
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
    }
}

@keyframes springRotate {
    0%{
        transform: scale3d(1,1,1);
    }
    30%{
        transform: scale3d(1, 0.95, 1);
    }
    40%{
        transform: scale3d(1, 1.05, 1);
    }
    50%{
        transform: scale3d(1, 0.95, 1);
    }
    65%{
        transform: scale3d(1, 1.05, 1);
    }
    75%{
        transform: scale3d(1, 0.95, 1);
    }
    100%{
        transform: scale3d(1, 1, 1) rotate(360deg);
    }
}

@keyframes rotateMove {
    0%{
        transform: translate(100%, 0);
        opacity: 0;
        visibility: hidden;
    }
    100%{
        transform: rotate(0, 0);
        opacity: 1;
        visibility: visible;
    }
}

@keyframes spinnerMove {
    0%{
        transform: rotate(0);
        left: 100%;
    }
    50%{
        transform: rotate(360deg);
        left: -3rem;
    }
    100%{
        transform: rotate(0);
        left: 100%;
    }
}


@keyframes bounce {
    0%{
        width: 8rem;
        height: 8rem;
    }
    30%{
        width: 7rem;
        height: 8rem;
    }
    50%{
        width: 9rem;
        height: 6rem;
        transform: translateY(8rem);
        
    }
    75%{
        width: 8.7rem;
        height: 8rem;
    }
    100%{
        transform: translateY(0);
    }
}

@keyframes bounce2 {
    0%{
        width: 6.5rem;
        height: 6.5rem;
    }
    30%{
        width: 5.5rem;
        height: 6.5rem;
    }
    50%{
        width: 7.5rem;
        height: 4.5rem;
        transform: translateY(6.5rem);
        
    }
    75%{
        width: 7.2rem;
        height: 6.5rem;
    }
    100%{
        transform: translateY(0);
    }
}

@keyframes bounce3 {
    0%{
        width: 4.5rem;
        height: 4.5rem;
    }
    30%{
        width: 3.5rem;
        height: 4.5rem;
    }
    50%{
        width: 4.5rem;
        height: 2.5rem;
        transform: translateY(4.5rem);
        
    }
    75%{
        width: 5.2rem;
        height: 4.5rem;
    }
    100%{
        transform: translateY(0);
    }
}

@keyframes shrink{
    50%{
        transform: scaleX(1.3);
    }
}

@keyframes clipPath {
    0%{
        clip-path: inset(0 0 0 100%);
    }
    50%{
        clip-path: inset(0 0% 0 0%);
    }
}

@keyframes marquee {
    0%{
        background-position: 0 0;
    }
    100%{
        background-position: 100% 0;
    }
}

@keyframes flying {
    0%{
        transform: scale(0.1) rotateY(180deg) rotateX(0deg) rotateZ(15deg);
        right: -45%;
        top: -12rem;
    }
    100%{
        transform:  scale(0.1) rotateY(180deg) rotateX(0deg) rotateZ(15deg);
        right: 100%;
        top: 20rem;
    }
}

@keyframes marqueeTransform {
    0%{
        transform: translateX(-100%);
    }
    100%{
        transform: translateX(100%);
    }
}

@keyframes plane {
    0%{
        transform: translateY(4rem);
    }
    50%{
        transform: translateY(0);
    }
    100%{
        transform: translateY(4rem);
    }
}

@keyframes plane-2 {
    0%{
        transform: translateY(3rem);
    }
    50%{
        transform: translateY(0);
    }
    100%{
        transform: translateY(3rem);
    }
}

@keyframes plane-3 {
    0%{
        transform: translateY(2rem);
    }
    50%{
        transform: translateY(0);
    }
    100%{
        transform: translateY(2rem);
    }
}

@keyframes fly-cycle {
	
	100% {
		background-position: -900px 0;
	}
	
}

@keyframes fly-right-one {
	
	0% {
		transform: scale(0.3) translateX(-10vw);
	}
	
	10% {
		transform: translateY(2vh) translateX(10vw) scale(0.4);
	}
	
	20% {
		transform: translateY(0vh) translateX(30vw) scale(0.5);
	}
	
	30% {
		transform: translateY(4vh) translateX(50vw) scale(0.6);
	}
	
	40% {
		transform: translateY(2vh) translateX(70vw) scale(0.6);
	}
	
	50% {
		transform: translateY(0vh) translateX(90vw) scale(0.6);
	}
	
	60% {
		transform: translateY(0vh) translateX(110vw) scale(0.6);
	}
	
	100% {
		transform: translateY(0vh) translateX(110vw) scale(0.6);
	}
	
}

@keyframes fly-right-two {
	
	0% {
		transform: translateY(-2vh) translateX(-10vw) scale(0.5);
	}
	
	10% {
		transform: translateY(0vh) translateX(10vw) scale(0.4);
	}
	
	20% {
		transform: translateY(-4vh) translateX(30vw) scale(0.6);
	}
	
	30% {
		transform: translateY(1vh) translateX(50vw) scale(0.45);
	}
	
	40% {
		transform: translateY(-2.5vh) translateX(70vw) scale(0.5);
	}
	
	50% {
		transform: translateY(0vh) translateX(90vw) scale(0.45);
	}
	
	51% {
		transform: translateY(0vh) translateX(110vw) scale(0.45);
	}
	
	100% {
		transform: translateY(0vh) translateX(110vw) scale(0.45);
	}
	
}

@keyframes vapour {
    0%{
        transform: translateY(0) scaleX(1);
        opacity: 0;
    }
    15%{
        opacity: 1;
    }
    50%{
        transform: translateY(-15rem) scaleX(1.2);
    }
    95%{
        opacity: 0;
    }
    100%{
        transform: translateY(-30rem) scaleX(1.3);
    }
}

