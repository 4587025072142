
.page-search-certi-result {
    min-height: 85rem;
}

.main.page-search-serti{
    min-height: auto;
    .table-sect{
        display: none;
    }
}

.certi-result-table{
    margin-top: -4rem;
    position: relative;
    z-index: 2;
    .table-heading{
        @include max(1024px) {
            display: none;
        }
    }
    .table-body{
        .table-item{
            display: flex;
            align-items: center;
            border-right: 1px solid #EDF2F2;
            @include max(1024px) {
                width: 100%;
                padding-bottom: 1rem;
            }
            &:last-child{
                border-right: none;
                @include max(1024px) {
                    padding-bottom: 0;
                }
            }
            .txt{
                display: block;
                @include max(1024px) {
                    width: 50%;
                }
            }
        }
        .label-table-item{
            width: 50%;
            font-weight: 700;
            display: none;
            @include max(1024px) {
                display: flex;
            }
        }
    }
    .table-row{
        @include max(1024px) {
            display: none;
            flex-direction: column;
            align-items: flex-start;
        }
    }
    .label-table{
        display: none;
        @include max(1024px) {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 2rem;
        }
        .ic{
            i{
                transition: all .3s;
            }
        }
        &.open{
            .ic{
                i{
                    transform: rotate(180deg);
                }
            }
        }
    }
    .table-item{
        font-size: 1.6rem;
        @include max(1320px) {
            font-size: 1.4rem;
        }
    }
    .table-wr{
        box-shadow: 0px 4px 35.3px 0px rgba(18, 27, 44, 0.08);

    }
    .btn{
        margin: 4.8rem auto 0;
        @include max(1200px) {
            margin: 2.8rem auto 0;
        }
    }
}