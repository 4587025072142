.ab-trainning{
    overflow: hidden;
}

.level-study-heading{
    .level-tab{
        margin-bottom: 4.8rem;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        align-items: center;
        gap: 1.6rem;
        @include max(1200px) {
            margin-bottom: 2.8rem;
        }
    }
    .level-tab-item{
        border: 1px solid #E1E8E8;
        cursor: pointer;
        min-height: 4rem;
        &.active{
            background-color: $color-pri;
            .txt{
                color: #F5F7F7;
            }
        }
        .txt{
            display: flex;
            color: #606969;
            font-size: 1.6rem;
            line-height: 150%;
            padding: 1rem 2.4rem;
        }
    }
}

.thumb-trainn{
    @include max(767px) {
        width: 70%;
        margin: 0 auto;
    }
    @include max(500px) {
        width: 100%;
    }
}

.thumb-train-wr {
    padding-top: calc(592/592*100%);
    position: relative;
    display: block;
    overflow: hidden;
    .img{
        position: absolute;
        img{
            width: 100%;
            height: 100%;
        }
        &:first-child{
            width: calc(1.8/6*100%);
            aspect-ratio: 1/1;
            left: 0;
            top: 14%;
        }
        &:nth-child(2){
            width: calc(1.8/6*100%);
            aspect-ratio: 176/313;
            left: 0;
            bottom: -1rem;
        }
        &:last-child{
            top: 0;
            right: 0;
            width: calc(4/6*100%);
            aspect-ratio: 384/592;
        }
    }
}
.content-trainn{
    @include flexCol;
    justify-content: space-between;
    @include max(767px) {
        order: -1;
        text-align: center;
    }
    .tt-sect{
        margin-bottom: 1.2rem;
    }
    .desc{
        margin-bottom: 2.4rem;
    }
    .btn{
        @include max(767px) {
            margin: 0 auto;
        }
    }
    .thumb-child{
        width: 28rem;
        aspect-ratio: 280/144;
        margin-top: 2rem;
        @include max(767px) {
            margin: 2rem auto 0;
        }
        @include max(500px) {
            width: 100%;
        }
        img{ 
            width: 100%;
            height: 100%;
        }
    }
}

.faq-sect{
    .faq-sect-heading{
        width: calc(6/12*100%);
        margin: 0 auto;
        @include max(1024px) {
            width: 100%;
        }
        .tt-sect{
            margin-bottom: 1.6rem;
        }
    }
    .faq-sect-body{
        margin-top: 4.8rem;
        @include max(1200px) {
            margin-top: 2.8rem;
        }
    }
}

.faq-list {
    .faq-item{
        &.active{
            .faq-head{
                .ic{
                    transform: rotate(180deg);
                }
            }
        }
    }
    .faq-head{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 2.4rem 0;
        border-bottom: 1px solid #D0D5D9;
        cursor: pointer;
        @include max(1200px) {
            padding: 2rem 0;
        }
        .ic{
            display: flex;
            width: 2.4rem;
            height: 2.4rem;
            transition: all .3s;
            flex-shrink: 0;
            cursor: pointer;
            img{
                width: 100%;
                height: 100%;
            }
        }
    }
    .faq-content{
        display: none;
        padding-top: 1.6rem;
        @include max(1200px) {
            font-size: 1.6rem;
        }
    }
}

.level-study-slider{
    margin-top: 4.8rem;
    @include max(1200px) {
        margin-top: 2.8rem
    }
    .level-tt-head-wr{
        width: calc(6/12*100%);
        margin: 0 auto 4.8rem;
        @include max(991px) {
            width: 100%;
            margin: 0 auto 2.8rem;
        }
        .tt-sect{
            margin-bottom: 1.6rem;
        }
    }
}

.level-study-thumb{
    .thumb{
        @include imgPd(682,1216);
        img{
            @include imgCover;
        }
    }
    .swiper-slide{
        .thumb{
            @include imgPd(682,1216);
            img{
                @include imgCover;
            }
        }
    }
}

.level-study-box {
    position: relative;
    z-index: 2;
    margin-top: -10rem;
    @include max(700px) {
        margin-top: 4rem;
    }
    .card-slider-block{
        .container{
            // width: calc(100vw - (100vw - calc(120rem - 2px))/2);
            @include max(1200px) {
                padding: 0 1.5rem;
            }
        }
        .box-navi{
            width: calc(123.5rem/2);
            justify-content: center;
            gap: 1.2rem;
            margin-top: 2.4rem;
            @include max(1230px) {
                width: calc(5/12*100%);
            }
            @include max(991px) {
                width: 100%;
            }
        }
        .btn-navi{
            width: 2.4rem;
            height: 2.4rem;
            display: flex;
            justify-content: center;
            align-items: center;
            position: static;
            transform: translate(0,0);
            .ic{
                width: 100%;
                height: 100%;
            }
            i{
                color: $color-third;
            }
        }
    }
    .swiper-slide {
        width: calc(123.5rem/2);
        height: auto;
        @include max(1230px) {
            width: calc(5/12*100%);
        }
        @include max(991px) {
            width: 50%;
        }
        @include max(700px) {
            width: 70%;
        }
        &.swiper-slide-active{
            .career-card{
                .career-card-inner{
                    background-color: $white;
                    box-shadow: 0px 4px 32px 0px rgba(18, 27, 44, 0.05);
                    &::before{
                        height: 100%;
                    }
                }
            }
        }
    }
    .career-card{
        .career-card-inner{
        }
        .tt-card{
            font-size: 2.4rem;
            @include max(1200px) {
                font-size: 2rem;
            }
        }
        .info-career{
            display: none;
        }
    }
}