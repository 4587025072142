.partner-heading{
    .stt{
        color: $color-pri;
        font-weight: 700;
        line-height: 150%;
    }
}

.partner-sect{
    overflow: hidden;
}

.partner-slider{
    overflow: hidden;
    .swiper{
        margin: 0 calc(-1 * var(--it-pd) );
    }
    .swiper-slide{
        width: 18% !important;
        padding: 0 var(--it-pd);
        @include max(1200px) {
            width: 22% !important;
        }
        @include max(991px) {
            width: 25% !important;
        }
        @include max(575px) {
            width: 30% !important;
        }
        .img{
            @include imgPd(158, 280);
            background-color: $white;
            transition: all .3s;
            border: 0.1rem solid transparent;
            &:hover{
                box-shadow: 0px 6px 15px 0px rgba(64, 79, 104, 0.05);
                border-color:#E9E9E9;
            }
            img{
                @include imgCover
            }
        }
    }
    .swiper-wrapper{
        transition-timing-function: linear !important;
    }
}