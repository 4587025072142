// .hd{
//     background-color: $black;
//     .hd-wr{
//         align-items: center;
//         justify-content: space-between;
//     }
// }

.hd{
    position: sticky;
    z-index: 20;
    left: 0;
    top: 0;
    width: 100%;
    height: calc(var(--hd-up) + var(--hd-bot));
    transition: all .4s ease-in-out;
    @include max(1200px) {
        height: var(--size-hd);
    }
    &.sticky{
        @include min(1200px) {
            height: calc(var(--hd-up) + var(--hd-bot) -5.9rem);
            .hd-up{
                height: calc(var(--hd-up) - 5.9rem);
                .hd-logo{
                    .custom-logo-link{
                        width: calc(var(--w-logo) - 3rem);
                    }
                    .logo-txt{
                        font-size: 1.4rem;
                    }
                }
            }
            .hd-bot{
                box-shadow: 0 0rem 3rem 1rem rgba(0, 0, 0, 0.3);
            }
        }
    }
    &.hide-hd{
        @include min(1200px) {
            transform: translate(0, -100%);
            visibility: hidden;
        }
    }
}

.hd-up{
    background-color: $color-pri;
    width: 100%;
    height: var(--hd-up);
    transition: all .4s;
    .hd-logo{
        display: flex;
        align-items: center;
        gap: 0.8rem;
        .custom-logo-link{
            display: flex;
            width: var(--w-logo);
            transition: all .4s;
            img{
                width: 100%;
                height: 100%;
            }
        }
        .logo-txt{
            font-size: 1.85rem;
            color: $color-second;
            font-weight: 600;
            line-height: 130%;
            text-transform: uppercase;
            transition: all .4s;
            @include max(1200px) {
                font-size: 1.4rem;
            }
            @include max(575px) {
                display: none;
            }
        }
    }
    .container{
        max-width: var(--container-hd);
    }
    .hd-up-wr{
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .hd-up-rt{
        display: flex;
        align-items: center;
    }
    .hd-up-news {
        display: flex;
        align-items: center;
        @include max(850px) {
            display: none;
        }
        .hd-up-news-link{
            font-size: 1.8rem;
            color: #FEFDFC;
            line-height: 150%;
            transition: all .3s;
            @include max(1200px) {
                font-size: 1.6rem;
            }
            &:hover{
                color: $color-second;
            }
            &.active{
                color: $color-second;
                font-weight: 700;
            }
            &:not(:last-child){
                position: relative;
                padding-right: 2.4rem;
                margin-right: 2.4rem;
                @include max(1200px) {
                    padding-right: 1.2rem;
                    margin-right: 1.2rem;
                }
                &::after{
                    content: '';
                    position: absolute;
                    right: 0;
                    top: 50%;
                    transform: translate(0, -50%);
                    width: 1px;
                    height: 70%;
                    background-color: #82878C;
                }
            }
        }
    }
    .hd-up-lg{
        display: flex;
        align-items: center;
        margin-left: 3.2rem;
        @include max(1200px) {
            margin-left: 1.6rem;
        }
        .lg{
            color: $white-pri;
            font-size: 2rem;
            font-weight: 700;
            line-height: 150%;
            text-transform: capitalize;
            transition: all .3s;
            @include max(1200px) {
                font-size: 1.6rem;
            }
            &:hover{
                color: $color-second;
            }
            &.active{
                color: #B3995D;
            }
            &:first-child{
                padding-right: 2rem;
                margin-right: 2rem;
                border-right: 1px solid $white-pri;
                @include max(1200px) {
                    padding-right: 1.4rem;
                    margin-right: 1.4rem;
                }
            }
        }
    }
}

.hd-bot{
    width: 100%;
    height: var(--hd-bot);
    background-color: $white;
    transition: all .4s;
    @include max(1200px) {
        display: none;
    }
    .container{
        max-width: 100%;
    }
    .hd-bot-wr{
        justify-content: center;
        height: 100%;
        @include max(1380px) {
            display: flex;
            justify-content: center;
        }
    }
}

// .hd-nav .dropdown > .menu-list > .dropdown .menu-list{
//     top: var(--top);
//     left: var(--left);
//     position: fixed;
// }

.hd-nav{
    >.menu-list{
        margin: 0 calc(-1 * var(--it-pd));
        @include max(1620px) {
            margin: 0 -2.4rem;
        }
        @include max(1320px) {
            margin: 0 -1.2rem;
        }
        // @include max(1380px) {
        //     margin: 0 calc(-1 * var(--it-pd)*1.6);
        // }
        >.menu-item{
            --line-current: calc(100% - (var(--it-pd)*4) );
            position: relative;
            display: flex;
            align-items: center;
            transition: .4s;
            @include max(1620px) {
                --line-current: calc(100% - 4.8rem);
            }
            @include max(1320px) {
                --line-current: calc(100% - 2rem);
            }
            &::after{
                content: '';
                position: absolute;
                left: 50%;
                bottom: 0;
                width: 0;
                height: 2px;
                transform: translate(-50%,0);
                background-color: $color-pri;
                transition: all .3s;
            }
            &.current-menu-item{
                &::after{
                    width: var(--line-current);
                }
                >.menu-link{
                    color: $color-pri;
                    font-weight: 700;
                }
            }
            &:not(:last-child){
                .menu-link{
                    border-right: 1px solid $color-third;
                }
            }
            >.menu-link{
                font-size: 1.8rem;
                color: $color-third;
                font-weight: 500;
                display: flex;
                align-items: center;
                flex-wrap: nowrap;
                white-space: nowrap;
                transition: .4s;
                padding: 0 calc(var(--it-pd)*2);
                @include min(2000px) {
                    padding: 0 1.8rem;
                }
                @include max(1620px) {
                    font-size: 1.6rem;
                    padding: 0 2.4rem;
                }
                @include max(1320px) {
                    padding: 0 1.2rem;
                }
                // @include max(1380px) {
                //     padding: 0 calc(var(--it-pd)*1.6);
                // }
                .ic-down{
                    display: flex;
                    margin-left: 0.8rem;
                    transition: .4s;
                    i{
                        font-size: 1.6rem;
                    }
                }
            }
            &:hover{
                &::after{
                    width: var(--line-current);
                }
                >.menu-link{
                    color: $color-pri;
                    .ic-down{
                        transform: rotate(180deg);
                    }
                }
                >.menu-list{
                    opacity: 1;
                    visibility: visible;
                }
            }
        }
    }
    .dropdown{
        >.menu-list{
            font-size: 1.6rem;
            position: absolute;
            top: 110%;
            left: 0;
            background-color: $color-pri;
            width: calc(100% + 7rem);
            opacity: 0;
            visibility: hidden;
            transition: .4s;
            box-shadow: 0 1rem 2rem 1rem rgba(0,0,0,0.2);
            .menu-item{
                .menu-link{
                    font-size: 1.5rem;
                    color: $white-pri;
                    display: flex;
                    padding: 1.2rem 1.6rem;
                    transition: all .3s;
                    &:hover{
                        color: $color-second;
                    }
                }
            }
            >.dropdown{
                >.menu-link{
                    display: flex;
                    justify-content: space-between;
                    .ic-down{
                        i{
                            transition: all .3s;
                        }
                    }
                }
                position: relative;
                &:hover{
                    >.menu-link{
                        .ic-down{
                            i{
                                transform: rotate(-90deg);
                            }
                        }
                    }
                    >.menu-list{
                        opacity: 1;
                        visibility: visible;
                    }
                }
                .menu-list{
                    font-size: 1.6rem;
                    position: fixed;
                    // top: 0;
                    // left: 100%;
                    top: var(--top);
                    left: var(--left);
                    background-color: $color-pri;
                    // max-width: 25rem;
                    max-width: 21rem;
                    opacity: 0;
                    visibility: hidden;
                    max-height: 25rem;
                    overflow: auto;
                    transition: .4s;
                    @include max(1420px) {
                        max-width: 18rem;
                    }
                }
            }
        }
    }
}




.nav-mb{
    .menu-list{
        .dropdown{
            .menu-list{
                width: 100%;
                position: unset;
                z-index: 0;
                background-color: transparent;
                transition: none;
                opacity: 1;
                visibility: visible;
                pointer-events: visible;
                margin-left: 1rem;
                border-left: 0.1rem solid $color-pri;
            }
        }
    }
}

.menu-mb{
    width: 35rem;
    position: fixed;
    top: var(--size-hd);
    left: 0;
    bottom: 0;
    background-color: $white;
    font-size: 1.6rem;
    font-weight: 500;
    padding-bottom: 2rem;
    overflow: auto;
    overflow-x: hidden;
    color: $color-pri;
    transform: translate(-101%, 0);
    transition: all .3s;
    @include max(575px) {
        width: 85%;
    }
    &.active{
        opacity: 1;
        visibility: visible;
        transform: translate(0,0);
    }
}

.nav-mb{
    margin-bottom: 2rem;
    >.menu-list{
        >.menu-item{
            padding-bottom: 1rem;
            &:last-child{
                padding-bottom: 0;
            }
            &.current-menu-item{
                >.menu-link{
                    color: $color-pri;
                    font-weight: 700;
                }
            }
            >.menu-link{
                display: flex;
                border-bottom: 1px solid #101112;
                padding: 1.2rem;
                .ic-down{
                    display: block;
                    padding-left: 1rem;
                    i{
                        transition: all .3s;
                    }
                    &.active{
                        i{
                            transform: rotate(180deg);
                        }
                    }
                }
            }
            &.dropdown{
                >.menu-link{
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 1.2rem;
                }
                >.menu-list{
                    margin-top: 1rem;
                    display: none;
                    >.menu-item{
                        &.current_page_item{
                            >.menu-link{
                                color: $color-pri;
                                font-weight: 700;
                            }
                        }
                        >.menu-list{
                            display: none;
                            border-left: none;
                            margin-left: none;
                            >.menu-item{
                                &.current_page_item{
                                    >.menu-link{
                                        color: $color-pri;
                                        font-weight: 700;
                                    }
                                }
                                .menu-link{
                                    padding: 0.6rem;
                                    padding-left: 1.2rem;
                                }
                            }
                        }
                        .menu-link{
                            display: flex;
                            justify-content: space-between;
                            padding: 1.2rem;
                            padding-right: 2rem;
                            .ic-down{
                                i{
                                    transition: all .3s;
                                }
                                &.active{
                                    i{
                                        transform: rotate(180deg);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.menu-mb-social{
    padding-left: 1.2rem;
    .social-list{
        .social-link{
            width: 2.6rem;
            height: 2.6rem;
        }
    }
}

.menu-mb-ct{
    font-family: var(--font-sec);
    margin-bottom: 2rem;
    padding: 0 1.2rem 0 1.2rem;
    .menu-item{
        font-size: 1.4rem;
        margin-bottom: 1rem;
        &:last-child{
            margin-bottom: 0;
        }
        .label-item{
            font-weight: 700;
        }
    }
}

// &.m-single-leaguer{
//     .menu-link{
//         color: $color-second;
//     }
// }



// current menu

.m-page-train {
    .hd-nav{
        >.menu-list{
            >.menu-item{
                &.m-single-train {
                    &::after{
                        width: var(--line-current);
                    }
                    >.menu-link{
                        color: $color-pri;
                        font-weight: 700;
                    }            
                }
            }
        }
    }
}

.m-page-leaguer {
    .hd-nav{
        >.menu-list{
            >.menu-item{
                &.m-single-leaguer {
                    &::after{
                        width: var(--line-current);
                    }
                    >.menu-link{
                        color: $color-pri;
                        font-weight: 700;
                    }            
                }
            }
        }
    }
}

.m-page-quality {
    .hd-nav{
        >.menu-list{
            >.menu-item{
                &.m-single-quality {
                    &::after{
                        width: var(--line-current);
                    }
                    >.menu-link{
                        color: $color-pri;
                        font-weight: 700;
                    }            
                }
            }
        }
    }
}

.m-page-student{
    .hd-nav{
        >.menu-list{
            >.menu-item{
                &.m-single-student {
                    &::after{
                        width: var(--line-current);
                    }
                    >.menu-link{
                        color: $color-pri;
                        font-weight: 700;
                    }            
                }
            }
        }
    }
}

.m-page-study{
    .hd-nav{
        >.menu-list{
            >.menu-item{
                &.m-single-study {
                    &::after{
                        width: var(--line-current);
                    }
                    >.menu-link{
                        color: $color-pri;
                        font-weight: 700;
                    }            
                }
            }
        }
    }
}

.m-page-partner{
    .hd-nav{
        >.menu-list{
            >.menu-item{
                &.m-single-partner {
                    &::after{
                        width: var(--line-current);
                    }
                    >.menu-link{
                        color: $color-pri;
                        font-weight: 700;
                    }            
                }
            }
        }
    }
}

.m-page-recruit{
    .hd-nav{
        >.menu-list{
            >.menu-item{
                &.m-single-recruit {
                    &::after{
                        width: var(--line-current);
                    }
                    >.menu-link{
                        color: $color-pri;
                        font-weight: 700;
                    }            
                }
            }
        }
    }
}

.m-page-post{
    .hd-nav{
        >.menu-list{
            >.menu-item{
                &.m-single-post {
                    &::after{
                        width: var(--line-current);
                    }
                    >.menu-link{
                        color: $color-pri;
                        font-weight: 700;
                    }            
                }
            }
        }
    }
}
