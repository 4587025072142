
.career-filter{
    padding: 0 1.5rem;
    @include max(1200px) {
        padding: 0
    }
}

.career-opp-heading{
    width: calc(7/12*100%);
    margin: 0 auto 4.8rem;
    @include max(1200px) {
        margin: 0 auto 2.8rem;
    }
    @include max(991px) {
        width: 100%;
    }
}

.career-filter-wr{
    @include row(1.6);
    align-items: flex-start;
    row-gap: var(--it-pd);
    @include max(1200px) {
        margin: 0 -0.8rem;
    }
    .career-filter-item{
        padding: 0 var(--it-pd);
        .input{
            display: flex;
            height: 4.9rem;
            align-items: center;
            width: 100%;
            // height: 100%;
        }
        &:first-child{
            width: calc(4/12*100%);
            @include max(700px) {
                width: 50%;
            }
            @include max(575px) {
                width: 100%;
            }
        }
        &:nth-child(2){
            width: calc(3/12*100%);
            @include max(850px) {
                width: calc(4/12*100%);
            }
            @include max(700px) {
                width: 50%;
            }
            @include max(575px) {
                width: 100%;
            }
        }
        &:nth-child(3) {
            width: calc(3/12*100%);
            @include max(850px) {
                width: calc(4/12*100%);
            }
            @include max(700px) {
                width: 50%;
            }
            @include max(575px) {
                width: 100%;
            }
        }
        &:last-child{
            font-size: 1.4rem;
            display: flex;
            align-items: center;
            width: calc(2/12*100%);
            font-family: var(--font-sec);
            @include max(850px) {
                width: auto;
            }
            @include max(700px) {
                width: 50%;
            }
            @include max(575px) {
                width: 100%;
            }
        }
    }
}

.career-opp-sect{
    overflow: hidden;
}