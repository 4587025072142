.majors-card {
    .thumb{
        @include imgPd(300, 384);
        margin-bottom: 1.2rem;
        &:hover{
            >img{
                transform: translate(-50%, -50%) scale(1.1);
            }
        }
        >img{
            @include imgCover;
            transition: all .3s;
        }
    }
    .tag{
        position: absolute;
        left: 1.2rem;
        bottom: 1.2rem;
        font-family: var(--font-sec);
        display: flex;
        align-items: center;
        gap: 1rem;
        padding: 1.4rem 3.5rem;
        background-color: #F5F7F7;
        box-shadow: 0px 10px 12px 0px rgba(0, 0, 0, 0.25);
        transition: all .3s;
        @include max(1200px) {
            padding: 1rem 1.5rem;
        }
        &:hover{
            background-color: $color-second;
            .txt{
                color: $white;
            }
            .ic{
                img{
                    filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(1%) hue-rotate(276deg) brightness(113%) contrast(101%);
                }
            }
        }
        .txt{
            font-weight: 500;
            font-size: 1.6rem;
            transition: all .3s;
            @include max(1200px) {
                font-size: 1.4rem
            }
        }
        .ic{
            @include icon(1.6, 1.6);
            @include max(1200px) {
                width: 1.2rem;
                height: 1.2rem;
            }
            img{
                width: 100%;
                height: 100%;
            transition: all .3s;
            }
        }
    }
    .majors-content{
        .tt-sect{
            margin-bottom: 1.2rem;
            @include lc(2);
            transition: all .3s;
            &:hover{
                color: $color-second;
            }
        }
        .desc{
            font-size: 1.6rem;
            @include lc(3);
            @include max(1200px) {
                font-size: 1.4rem;
            }
        }
    }
}